.signatory_container {

  .client_details_container {
    padding-left: 5px;
    border-left: 5.83333px solid #2773b1;

    h4 {
      font-family: "Raleway";
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 26px;
      text-transform: uppercase;
      color: #646569;
      margin-bottom: 0px;
    }

    p {
      font-family: "Raleway";
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 38px;
      color: #646569;
    }
  }

  .signatory_details_container {
    background-color: #ffffff;
    padding: 25px 10px;
    width: inherit;
    margin: auto;

    .title {
      font-family: "Lato";
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 18px;
      color: #6e6e6e;
    }

    .signatory_form_container {
      label {
        font-family: "Lato";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: #000000;
      }

      input {
        font-family: "Lato";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        padding: 16px 24px;
        color: #000000;
      }

      #phone_number {
        // border-left: 0px;
      }

      .datepicker-payscheme {
        &:focus-visible {
          outline: 0;
        }

        &:focus {
          border-color: #86b7fe;
        }
      }

      .signatory_save_btn_container {
        display: flex;
        justify-content: center;

        @media (min-width: 768px) {
          justify-content: end;
        }
      }
    }
  }
}

.change_signatory_warning_modal {
  h4 {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #000000
  }

  .modal-header {
    box-shadow: none;
    padding-bottom: 0;
  }

  .btn-close {
    display: none;
  }
}

.signatory-help-text {
  max-width: 471px;
  padding: 10px;
  background: #E8E8E8;
  border-radius: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
}

.custom-form {

  .form-check {
    @media (max-width: 360px) {
      display: flex !important;
    }
  }
}

.bank-check {
  .form-check {
    display: flex !important;
  }
}

.form-select:disabled {
  background: #e8e8e8 !important;
  border: 1px solid #e8e8e8 !important;
}

.state-no-data {
  font-weight: 400;
  font-size: 16px;
  color: #6E6E6E;
  background: #FFFFFF;
  text-align: left;
}

.new_signatory{
  h5{
    font-size: 16px;
    font-weight: 600;
  }
  .custom-form .form-label{
    font-size: 14px;
  }
}