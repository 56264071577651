.offer-view-link {
    width: 200px;
    padding: 9px 16px;

    @media (max-width: 299px) {
        width: 82px !important;
    }

    @media (max-width: 768px) {
        display: inline-block;
        text-align: center;
    }

   

    a {
        color: var(--white);
    }
}