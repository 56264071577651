.emp-state-tax-view{
    iframe{
        min-height: 800px;
    }
    .emp-iframe-loading {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 250px;
        font-size: 24px;
        font-weight: bold;
    }
}