.sign-comapny-table {
    background: #FFF;
    padding: 20px;

    .sign-heading {
        font-weight: 400;
        font-size: 18px;
        line-height: 18px;
        color: #6E6E6E;

        h5 {
            font-weight: 700;
            font-size: 20px;
            // line-height: 18px;
            color: #6E6E6E;
            margin-bottom: 13px;
        }
    }

    .header_bg_table {
        border-radius: 5px 5px 0px 0px;
        border: 0.5px solid #e8e8e8;

        thead tr {
            background: #EBF2F8;
            height: 56px;

            th {
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                color: #000000;
            }
        }

        td {
            white-space: break-spaces;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #515253;
        }
    }
}

.sign-no-data {
    font-weight: 400;
    font-size: 16px;
    // line-height: 18px;
    color: #6E6E6E;
    background: #FFFFFF;
    height: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;

    h4 {
        font-weight: 700;
        font-size: 24px;
        // line-height: 18px;
        color: #6E6E6E;
    }
}

.sign-doc-name {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-decoration-line: underline;
    color: #2773B1;
    cursor: pointer;
}

.sign-status {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
    border-radius: 10px !important;
    padding: 7px 17px;
}

.sign-complete-status {
    background: #117A00;
}

.sign-incomplete-status {
    background: #DC3545;
}

.status-column {
    word-break: break-word;
}

.sign-error {
    ul {
        li {
            list-style: circle !important;
            padding: 10px;
            text-align: left;
            // color: var(--red);
        }
    }
}

.inside-table-sign-error {
    .sign-no-data {
        font-weight: 400;
        font-size: 16px;
        color: #6E6E6E;
        background: #FFFFFF;
        height: 150px;
        display: unset;
        justify-content: unset;
        align-items: unset;
        flex-direction: unset;
        text-align: left;
    }
}

.sign-no-empty-data {
    .sign-no-data {
        padding: 50px 0;
        height: auto;
    }
}

.same-sign-link {
    text-decoration: none !important;
    color: #000 !important;
}
.new_signDoc{
    h4,h5{
        font-size: 16px !important;
        font-weight: 600 !important;
    }
    tbody tr td,
    .sign-doc-name ,.sign-heading div{
        font-size: 14px !important;
    }
}