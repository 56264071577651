.main-board {
  padding: 20px;
}
.alert-card {
  width: 100%;
  background: #ffffff;
  .alert-round {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 34px 0 12px 0;
    width: 80px;
    height: 80px;
    background-color: #ffe9e9;
    border-radius: 50%;
    filter: drop-shadow(0px 0px 7.191px rgba(0, 0, 0, 0.15));
    .alert-icon {
      width: 48px;
      height: 48px;
    }
  }
  .alert-text {
    color: #f04b4b;
    font-family: Lato;
    font-size: 24px;
    font-weight: 700;
    margin: 32px 0 0 !important;
  }
  .alert-hr {
    width: 100%;
    background: #d9d9d9;
    height: 1px;
    margin: 28px 0 36px 0;
  }
}
.process-payroll {
  background: #ffffff;
  border-radius: 8px;

  .page-title {
    font-weight: 700;
    font-size: 16px;
    color: #6e6e6e;
    padding: 15px 15px 0 15px;
  }

  .server-side-table {
    padding: 15px;

    .search {
      display: none;
    }

    .table .thead-dark th {
      background: #ebf2f8;
    }

    .react-bootstrap-table-pagination {
      background: #fff;
      padding: 30px 10px 20px 10px;
    }
  }
}

.run-btn:disabled,
.pay-btn:disabled {
  background: #dbdbdb;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2), 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  border: transparent;
}

.pay-btn,
.run-btn {
  background: #2773b1;
  border: 1px solid #2773b1;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2), 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  width: 175px;
}

.run-btn {
  @media (max-width: 400px) {
    margin-top: 10px;
  }
}

.pay-pending-status {
  background: #efbb02;
  border-radius: 10px;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  text-transform: capitalize;
}

.pay-incomplete-status {
  background: #35ead2;
  border-radius: 10px;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  text-transform: capitalize;
}

.pay-complete-status {
  background: #117a00;
  border-radius: 10px;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  // text-transform: capitalize;
}

.payroll-warning {
  background: #ffffff;
  padding: 0;
  border-radius: 8px;
  margin: auto;
  width: 90%;
  .payroll-warning-message {
    h4 {
      font-weight: 700;
    }
    div {
      font-family: "Lato";
      font-weight: 600;
      line-height: 34px;
      letter-spacing: 0em;
      color: #7a7a7a;
    }
    font-weight: 500;
    font-size: 16px;
    color: #6e6e6e;
    background: #ffffff;
    height: 150px;
    display: unset;
    justify-content: unset;
    align-items: unset;
    flex-direction: unset;
    text-align: left;
  }

  ul {
    margin-top: 1rem !important;

    li {
      list-style: disc !important;
      padding: 8px 10px;
      text-align: left;
      color: #000;
      font-weight: 550;
      line-height: 24px;
      a {
        color: #2773b1;
        text-decoration: underline;
        text-underline-offset: 3px;
      }
    }
  }
}

.new_payroll {
  .payroll-warning h4 {
    font-size: 16px !important;
    font-weight: 600;
  }

  .payroll-warning div {
    // font-size: 14px !important;
  }
}

// new payroll design

.payroll-content {
  background: #ffffff;

  .initial-payroll {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lato";
    flex-direction: column;
    border-bottom: 1px solid #d9d9d9;

    div {
      margin: 10px 0px;
    }

    .payroll-icon {
      box-shadow: 0px 0px 7.191011905670166px 0px #00000026;
      width: 80px;
      height: 80px;
      background: #ebf2f8;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 48.54px;
        height: 48.54px;
        padding: 4.85px 2.43px 2.43px 7.28px;
      }
    }

    .payroll-title {
      color: #7a7a7a;
      font-size: 24px;
      font-weight: 700;
      line-height: 29px;
      letter-spacing: 0em;
      text-align: left;
    }

    .payroll-subtitle {
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
      color: #7a7a7a;
    }
  }

  .payroll-instructions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "Lato";

    .heading {
      font-size: 24px;
      font-weight: 700;
      line-height: 29px;
      letter-spacing: 0em;
      text-align: left;
      color: #252525;
    }

    ol {
      li {
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        color: #7a7a7a;
        padding: 10px 0;
      }
    }
  }
}

.prepare-btn {
  padding: 11px 21px 11px 21px;
  border-radius: 3px;
  background: #2773b1;
  box-shadow: 0px 1px 3px 0px #0000001a !important;
  color: #ffffff;
  font-size: 11px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: center;

  &:disabled {
    background: #aaaaaa !important;
    color: #ffffff !important;
    border: 1px solid #aaaaaa !important;
    padding: 10px 26px;
  }
}
.back_icon {
  margin-right: 10px;
}
.back_btn {
  border: none;
  background: transparent;
}
.waiting_modal {
  .modal-header {
    display: none;
  }
}

.showWait {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  div {
    padding: 5px 5px;
  }
}

.waiting-text {
  img {
    width: 22px;
    height: 24px;
  }
  font-family: "Lato";
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  color: #2773b1;
  @media screen and (max-width: 768px) {
    font-size: 14px;
    img {
      width: 14px;
      height: 14px;
    }
  }
}

.show-error-conetnt {
  font-family: "Lato";
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #646569;

  div {
    padding: 5px 5px;
    width: 300px;
    text-align: center;
  }
}

.cancel-payroll {
  width: 118px;
  height: 36px;
  padding: 11px 21px 11px 21px;
  border-radius: 3px;
  background: #ffff;
  box-shadow: 0px 1px 3px 0px #0000001a !important;
  border: 1px solid #2773b1;
  color: #2773b1;
  font-size: 11px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: center;

  &:hover {
    background: #ffff;
    box-shadow: 0px 1px 3px 0px #0000001a !important;
    border: 1px solid #2773b1;
    color: #2773b1;
  }
}

.exiting-payroll {
  background: #ffffff;

  .header {
    .card {
      border: none;
    }

    .card-header {
      font-family: "Lato";
      font-size: 24px;
      font-weight: 700;
      line-height: 29px;
      letter-spacing: 0em;
      text-align: left;
      color: #515253;
      background-color: #fff;
      border: none;
      border-bottom: 1px solid #e8e8e8;
      border-radius: 0;
      padding: 15px 15px 15px 15px;
    }

    .card-text {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: "Lato";
      font-size: 16px;
      font-weight: 500;
      line-height: 26px;
      letter-spacing: 0em;
      text-align: left;

      .heading {
        span {
          color: #2773b1;
        }
      }
    }
  }
}

.payroll-add-title {
  font-family: "Lato";
  font-size: 22px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
}
.mb-6 {
  margin-bottom: 6px !important;
}
.text-left {
  text-align: left !important;
}
.payroll-info-container {
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    margin: 0;
  }
  @media screen and (max-width: 768px) {
    align-items: start;
    img {
      margin: 5px 0 0 !important;
    }
  }
}
.payroll-data-info-container {
  display: flex;
  align-items: center;
}
@media (min-width: 576px) {
  .payroll-gusto-pto-modal-wrapper {
    .modal-dialog {
      max-width: 1000px;
    }
  }
}
.modal-payroll-manage-footer {
  .btn-primary:disabled {
    color: #fff;
    &:hover {
      color: #fff;
    }
  }
}
.modal-manage-payroll-button {
  font-family: "Lato";
  font-size: 11px;
  font-weight: 400;
  line-height: 14px;
  text-align: center;
  padding: 0.635rem 2.5rem;
  color: #fff;
  border-radius: 3px;
  &:hover {
    color: #fff;
  }
}
.notification-alert-wrapper {
  display: flex;
  border: 1px solid #f3f3f3;
  padding: 1.5rem 1rem;
}
.notification-alert-title {
  display: flex;
  flex-direction: column;
  padding: 0 0 0 1.75rem;
}
.notification-alert-title h4 {
  font-family: "Lato";
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  color: #f04b4b;
}
.notification-alert-title h5 {
  font-family: "Lato";
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #6e6e6e;
}
.manage-pop-sub-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 0 0 1rem;
}
.manage-pop-title {
  display: flex;
  h5 {
    font-family: "Lato";
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    color: #6e6e6e;
    padding: 0 0 0 0.5rem;
    margin: 0;
  }
  img {
    width: 24px;
    height: 24px;
  }
}
.manage-pop-completed {
  display: flex;
}
.manage-pop-completed h5 {
  font-family: "Lato";
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  color: #33b033;
  padding: 0 8px 0 0;
}
.manage-pop-completed img {
  width: 20px;
  height: 20px;
}
.manage-pop-btn-wrapper {
  display: flex;
  justify-content: end;
  padding: 1.5rem 1rem;
}
.manage-pop-btn-wrapper button {
  box-shadow: 0px 1px 3px 0px #0000001a;
  box-shadow: 0px 1px 2px 0px #00000033;
  background: #c5c5c5;
  border-radius: 3px;
  border: 1px solid #c5c5c5;
  font-family: "Lato";
  font-size: 11px;
  font-weight: 400;
  line-height: 14px;
  color: #fff;
  padding: 0.75rem 1rem;
  text-transform: uppercase;
}
.manage-pop-content {
  // border: 1px solid #f3f3f3;
  padding: 0.5rem 1rem 0.5rem 0rem;
}
.manage-pop-incomplete {
  display: flex;
  a {
    display: flex;
    align-items: center;
    h5 {
      font-family: "Lato";
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      color: #2773b1;
      padding: 0 12px 0 0;
      margin: 0;
      text-decoration: underline;
      text-underline-offset: 5px;
    }
    img {
      width: 13px;
      height: 13px;
    }
  }
}
// .manage-pop-incomplete h5 {
//   font-family: "Lato";
//   font-size: 16px;
//   font-weight: 600;
//   line-height: 22px;
//   color: #2773b1;
//   padding: 0 12px 0 0;
// }
.manage-pop-incomplete .modal-manage-header {
  font-family: "Lato";
  font-size: 24px;
  font-weight: 400;
  line-height: 28.8px;
  justify-content: space-between !important;
  padding: 18px 20px !important;
  border: 1px solid #dee2e6;
  background: #dee2e6;
  color: #292d32;
  img {
    cursor: pointer;
  }
}
.manage-payroll-modal {
  padding: 16px 20px 8px !important;
  p {
    font-family: "Lato";
    font-size: 18px;
    font-weight: 400;
    line-height: 34px;
    color: #646569;
  }
}
.modal-manage-header {
  background: #edf1f2;
  h3 {
    font-family: "Lato";
    font-size: 24px;
    font-weight: 400;
    line-height: 28.8px;
    color: #292d32;
    margin: 0;
  }
  img {
    cursor: pointer;
  }
}
@media screen and (max-width: 600px) {
  .notification-alert-wrapper {
    flex-direction: column;
    align-items: center;
  }
  .notification-alert-title {
    display: flex;
    flex-direction: column;
    padding: 0;
  }
  .notification-alert-wrapper img {
    width: 70px;
  }
  .manage-pop-content {
    padding: 0.5rem 0rem 0rem;
  }
  .manage-pop-incomplete {
    a {
      align-items: center;
      h5 {
        padding: 0;
      }
    }
  }
  .manage-payroll-modal {
    padding: 16px 12px 8px !important;
    p {
      font-size: 16px;
      line-height: 30px;
      margin: 0;
    }
  }
  .manage-pop-sub-wrapper {
    flex-direction: column;
    align-items: center;
    gap: 0.25rem;
  }
  .modal-payroll-manage-footer {
    justify-content: center;
  }
}
@media screen and (max-width: 1024px) {
  .payroll-gusto-pto-modal-wrapper {
    .modal-dialog {
      width: 95%;
    }
  }
}
