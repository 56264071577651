.personal_container {
  @media (max-width: 515px) {
    position: relative;
  }

  .datepicker-payscheme {
    height: auto !important;
    padding: 10px 16px 8px 24px !important;
  }

  .custom-select div:first-child {
    padding-bottom: 3px;
    padding-top: 3px;
  }

  .mb_3 {
    margin-bottom: 24px;
  }

  .manage_dropdown {
    .input-group {
      justify-content: end;
    }

    button {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 12px 24px;
      width: 125px;
      height: 40px;
      background: #ffffff;
      border: 1px solid #515253;
      border-radius: 5px;
      font-family: "Lato";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: #515253;
    }

    .dropdown-menu {
      border: 0px;
      width: 231px;
      padding: 0;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);

      @media (max-width: 515px) {
        position: absolute;
      }
    }

    .manage_dropdown_items {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 100%;
      color: #515253;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0.8rem;
      gap: 4px;
      background: #ffffff;
      border-radius: 8px;
    }
  }

  #input-group-dropdown-1 {
    @media (max-width: 575px) {
      padding: 12px 24px;
      height: 42px;
      background: #ffffff;
      border: 1px solid #515253;
      border-radius: 5px;
    }
  }

  .profile_image_container {
    .profile_image {
      width: 95px;
      height: 95px;
      border-radius: 47.99px;
      border: 1px solid #cccccc;
      font-family: "Lato";
      padding: 0px;
      display: flex !important;

      div {
        width: 95px;
        height: 95px;
        border-radius: 47.99px;
        font-family: "Lato";
        -webkit-clip-path: circle(50% at 50% 50%);
        clip-path: circle(50% at 50% 50%);
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: fit-content;
          height: inherit;
          object-fit: contain;
        }
      }

      .custom_file_input {
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
    }

    @media (max-width: 575px) {
      display: flex;
      justify-content: center;

      .profile_image {
        width: 140px;
        height: 140px;
        border-radius: 120px;
        display: flex;
        justify-content: center;
        align-items: center;

        .profile_photo_img {
          width: inherit;
          height: inherit;
        }
      }
    }
  }

  #input-group-dropdown-1::after {
    margin-left: 10px;
  }

  button {
    color: white;
    width: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.profile_personal_image {
  width: 74px;
  height: 88px;
  // padding: 13px;
}

.personal-phone .input-group-text {
  border: 1px solid #e8e8e8 !important;
}

@media (max-width: 768px) {
  .manage_dropdown_container {
    align-items: center !important;
  }
}

.del-profile {
  font-size: 18px;
  text-align: center;
  padding-bottom: 15px;
}