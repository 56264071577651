.reports-csv-filter-wrapper {
  margin: 1rem;
}
.reports-csv-filter-header {
  h5 {
    span {
      text-transform: uppercase !important;
    }
  }
}
.reports-csv-table-wrapper {
  background: #fff;
  border: 1px solid #e2e8f0;
  padding: 1rem 0.5rem;
  max-width: -webkit-fill-available;
  overflow-x: scroll;
}
.reports-download-scroll-wrapper {
  min-width: 1300px;
  overflow-y: scroll;
  max-height: 400px;
  overflow-x: hidden;
}
.reports-download-table-header {
  background: #ebf2f8;
  position: sticky;
  top: 0;
  z-index: 1;
}
.reports-download-table-scroll-wrapper {
  th,
  td {
    font-family: "Lato";
    font-size: 14px;
    line-height: 17.6px;
    color: #515253;
  }
  th {
    font-weight: 700;
  }
  td {
    font-weight: 400;
  }
}
.filter-section-wrapper {
  background: #fff;
  margin: 0 0 1rem;
}
.filter-section-title-wrapper {
  background: #f9f9f9;
  padding: 1rem 1.25rem;
  display: flex;
  align-items: center;
  h5 {
    font-family: "Lato";
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    text-transform: uppercase;
    color: #646569;
    margin: 0;
  }
  img {
    height: 16px;
    margin: 0 5px 0 0;
  }
}
.filter-section-select-wrapper {
  background: #fff;
  padding: 1rem 1.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.reports-download-filter-dates-wrapper {
  display: flex;
  justify-content: space-between;
  width: 35%;
  input {
    height: 40px;
  }
  label {
    font-family: "Lato";
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    span {
      color: red;
    }
  }
}
.reports-download-approve-btn-active {
  font-family: "Lato";
  font-size: 11px;
  font-weight: 400;
  line-height: 14px;
  text-align: center;
  padding: 0.75rem 2.15rem;
  border-radius: 3px;
  color: #fff;
  background: #2773b1;
  border-radius: 3px;
  border: 1px solid #2773b1;
}
.csv-client-selection-wrapper {
  .css-b62m3t-container {
    min-width: 170px;
  }
}
.btn-min-value-wrapper {
  min-width: 179px;
  max-height: 40px;
}
.back-btn-cus-width-wrapper {
  width: fit-content;
}
// Responsive
@media (max-width: 1024px) {
  .filter-section-select-wrapper {
    flex-direction: column;
    grid-gap: 1rem;
    .css-b62m3t-container {
      width: 100%;
    }
  }
  .report-export-csv-wrapper {
    width: 100%;
    button {
      width: 100%;
    }
  }
  .reports-download-filter-dates-wrapper {
    width: 100%;
  }
  .reports-download-apply-btn {
    width: 100%;
    button {
      width: 100%;
    }
  }
  .reports-download-filter-dates-wrapper {
    flex-direction: column;
    grid-gap: 1rem;
  }
  .csv-client-selection-wrapper {
    width: 100%;
  }
  .reports-download-filter-dates-wrapper {
    .date-selector {
      width: 100%;
    }
    .date-selector-btn {
      width: 100%;
      margin: 0.5rem 0;
    }
  }
}
@media screen and (max-width: 600px) {
  .reports-download-filter-dates-wrapper {
    .date-selector {
      width: 100%;
    }
    .date-selector-btn {
      width: 100%;
      margin: 0.5rem 0;
    }
  }
  .reports-csv-filter-wrapper {
    margin: 0;
    .setting-backbutton {
      margin: 0 1rem;
    }
  }
}
