.paystub_container {
  .paystub_btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10.273px 20.6089px 10.8342px 20.8094px;
    background: #edf1f2;
    border: 1.00225px solid #dddddd;
    width: 100%;
    font-family: "Lato";
    font-style: normal;
    font-weight: 700;
    font-size: 14.0314px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: #6e6e6e;
    &:hover{
        cursor: pointer;
    }
  }
  .active{
    background-color: white;
  }
  .pagination {
    display: flex;
    justify-content: end;
    button {
      padding: 10px;
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      font-family: "Lato";
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      margin-right: 5px;
    }
    .active_btn {
      background: #2773b1;
      border-radius: 4px;
      color: white;
    }
    .non_active_btn {
      background: #ffffff;
      border: 1px solid #d0d0d0;
      border-radius: 4px;
      color: #333333;
    }
  }
}
