.sign-box {
  background: var(--white);
  border: 2px solid var(--blue);
  border-radius: 6px;
  color: var(--blue);
  text-align: center;
  padding: 15px 20px;
  cursor: pointer;
  //   margin-bottom: 15px;

  .title {
    font-weight: 600;
    font-size: 16px;
    line-height: 19.2px;
    letter-spacing: -0.32px;
  }

  .sub-title {
    font-weight: 400;
    font-size: 16px;
    line-height: 19.2px;
  }
}

.signatureCanvas {
  border: 1px solid black;
  width: 100%;
  min-height: 150px;
}

.sign-tab {
  border: none;
  display: none;

  .nav-link {
    width: 96px;
    height: 48px;
    background: var(--white);
    border: 1px solid var(--blue);
    border-radius: 0px;
  }

  .nav-link.active {
    background: var(--blue);
    color: var(--white);
  }
}

.clear-sign {
  font-weight: 800;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  text-decoration-line: underline;
  color: var(--blue);
  cursor: pointer;
}

.e-sign-box {
  background: #f7f7f7;
  border: 1px solid #aaaaaa;
  border-radius: 6px;
  padding: 12px;
}

.upload-modal-open {
  .view-hand-book-modal {
    z-index: 0;
  }
}

.sign-upload-doc {
  .dmajdp .file-types {
    max-width: unset !important;
    display: block !important;
    margin-top: 10px !important;
  }
}

.upload-e-sign {
  height: 112px;
  width: 300px;
}
.text-error {
  p {
    color: #f04b4b;
    margin: 1rem 0;
  }
}
