.header-form {
    top: 0;
    position: fixed;
    width: 100%;
    z-index: 100;
    height: 82px;
    background: var(--white);
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);
    padding: 15px;

    .navbar-text {
        font-weight: 800;
        font-size: 16px;
        line-height: 19px;
        color: var(--black4);
    }
}