.header-nav-main {
    background: var(--primary);
    height: 82px;
    font-family: 'Inter';

    @media (max-width: 700px) {
        display: none
    }

    .nav-link {
        display: flex;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        color: var(--white);
        width: 100%;
        padding: 9px 24px !important;
        align-items: center;
        font-family: 'Inter';

        div:first-child {
            margin-right: 8px;
        }
    }

    .username-dropdown {
        .dropdown-toggle::after {
            margin-top: 7px;
        }
    }

    .navbar-nav .show>.nav-link,
    .navbar-nav .nav-link.active {
        color: var(--white);
    }
}

.user-menu {
    margin-left: 10px;
    padding: 5px;
    cursor: pointer;

    .user-name {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 100%;
        margin-bottom: 5px;
        color: #FFFFFF;
    }

    .user-role {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 15px;
        color: #FFFFFF;
    }
}

.signout-dropdown {
    padding: 10px;
    position: absolute;
    top: 58px;
    background: #FFF;
    border-radius: 5px;
    width: 100%;
    z-index: 10;

    a {
        padding: 2px 0px !important;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: var(--black);

        .nav-text {
            border-bottom: 2px solid #EFEFEF;
        }
    }
}

.name-text {
    .dropdown-toggle.nav-link {
        padding: 11px 22px 11px 10px !important;

        // @media (min-width: 700px) and (max-width: 900px) {
        //     padding: 11px 0px 0px 10px !important;
        // }
    }

}

.header-dropdown {


    .dropdown-menu[data-bs-popper] {
        // left: -50px !important;
        right: 20px;
        border: none;
        left: unset;
        min-width: 180px;
        top: 45px;
        border-radius: 5px;
        padding: 0;
    }

    .dropdown-item {
        color: #515253;
        font-family: 'Inter';
        cursor: pointer;
        padding: 11px;
        border-radius: 5px;
        font-size: 14px;

    }

    .dropdown-item:active,
    .dropdown-item:hover,
    .dropdown-item:focus {
        color: unset;
        text-decoration: none;
        background-color: #FFF !important;
        cursor: pointer;
        border-radius: 5px;
    }


    .dropdown-toggle::after {
        display: inline-block;
        width: 8px;
        height: 8px;
        margin: 0 0 0 14px;
        vertical-align: middle;
        content: "";
        border: .3em solid;
        border-width: 0 .15em .15em 0;
        transform: rotateZ(45deg)
    }
}

.userprofileImg {
    width: 32px;
    height: 32px;
    margin-top: 5px;
}

.img-circle {
    border-radius: 50%;
    // border: 2px solid #ccc;
    // padding: 1px;
}

.mobile-bell {
    color: #6e6e6e !important;
}

.bell {
    display: block;
    width: 20px;
    height: 20px;
    font-size: 40px;
    color: #FFF;
    -webkit-animation: ring 4s .7s ease-in-out infinite;
    -webkit-transform-origin: 50% 4px;
    -moz-animation: ring 4s .7s ease-in-out infinite;
    -moz-transform-origin: 50% 4px;
    animation: ring 4s .7s ease-in-out infinite;
    transform-origin: 50% 4px;
}

@-webkit-keyframes ring {
    0% {
        -webkit-transform: rotateZ(0);
    }

    1% {
        -webkit-transform: rotateZ(30deg);
    }

    3% {
        -webkit-transform: rotateZ(-28deg);
    }

    5% {
        -webkit-transform: rotateZ(34deg);
    }

    7% {
        -webkit-transform: rotateZ(-32deg);
    }

    9% {
        -webkit-transform: rotateZ(30deg);
    }

    11% {
        -webkit-transform: rotateZ(-28deg);
    }

    13% {
        -webkit-transform: rotateZ(26deg);
    }

    15% {
        -webkit-transform: rotateZ(-24deg);
    }

    17% {
        -webkit-transform: rotateZ(22deg);
    }

    19% {
        -webkit-transform: rotateZ(-20deg);
    }

    21% {
        -webkit-transform: rotateZ(18deg);
    }

    23% {
        -webkit-transform: rotateZ(-16deg);
    }

    25% {
        -webkit-transform: rotateZ(14deg);
    }

    27% {
        -webkit-transform: rotateZ(-12deg);
    }

    29% {
        -webkit-transform: rotateZ(10deg);
    }

    31% {
        -webkit-transform: rotateZ(-8deg);
    }

    33% {
        -webkit-transform: rotateZ(6deg);
    }

    35% {
        -webkit-transform: rotateZ(-4deg);
    }

    37% {
        -webkit-transform: rotateZ(2deg);
    }

    39% {
        -webkit-transform: rotateZ(-1deg);
    }

    41% {
        -webkit-transform: rotateZ(1deg);
    }

    43% {
        -webkit-transform: rotateZ(0);
    }

    100% {
        -webkit-transform: rotateZ(0);
    }
}

@-moz-keyframes ring {
    0% {
        -moz-transform: rotate(0);
    }

    1% {
        -moz-transform: rotate(30deg);
    }

    3% {
        -moz-transform: rotate(-28deg);
    }

    5% {
        -moz-transform: rotate(34deg);
    }

    7% {
        -moz-transform: rotate(-32deg);
    }

    9% {
        -moz-transform: rotate(30deg);
    }

    11% {
        -moz-transform: rotate(-28deg);
    }

    13% {
        -moz-transform: rotate(26deg);
    }

    15% {
        -moz-transform: rotate(-24deg);
    }

    17% {
        -moz-transform: rotate(22deg);
    }

    19% {
        -moz-transform: rotate(-20deg);
    }

    21% {
        -moz-transform: rotate(18deg);
    }

    23% {
        -moz-transform: rotate(-16deg);
    }

    25% {
        -moz-transform: rotate(14deg);
    }

    27% {
        -moz-transform: rotate(-12deg);
    }

    29% {
        -moz-transform: rotate(10deg);
    }

    31% {
        -moz-transform: rotate(-8deg);
    }

    33% {
        -moz-transform: rotate(6deg);
    }

    35% {
        -moz-transform: rotate(-4deg);
    }

    37% {
        -moz-transform: rotate(2deg);
    }

    39% {
        -moz-transform: rotate(-1deg);
    }

    41% {
        -moz-transform: rotate(1deg);
    }

    43% {
        -moz-transform: rotate(0);
    }

    100% {
        -moz-transform: rotate(0);
    }
}

@keyframes ring {
    0% {
        transform: rotate(0);
    }

    1% {
        transform: rotate(30deg);
    }

    3% {
        transform: rotate(-28deg);
    }

    5% {
        transform: rotate(34deg);
    }

    7% {
        transform: rotate(-32deg);
    }

    9% {
        transform: rotate(30deg);
    }

    11% {
        transform: rotate(-28deg);
    }

    13% {
        transform: rotate(26deg);
    }

    15% {
        transform: rotate(-24deg);
    }

    17% {
        transform: rotate(22deg);
    }

    19% {
        transform: rotate(-20deg);
    }

    21% {
        transform: rotate(18deg);
    }

    23% {
        transform: rotate(-16deg);
    }

    25% {
        transform: rotate(14deg);
    }

    27% {
        transform: rotate(-12deg);
    }

    29% {
        transform: rotate(10deg);
    }

    31% {
        transform: rotate(-8deg);
    }

    33% {
        transform: rotate(6deg);
    }

    35% {
        transform: rotate(-4deg);
    }

    37% {
        transform: rotate(2deg);
    }

    39% {
        transform: rotate(-1deg);
    }

    41% {
        transform: rotate(1deg);
    }

    43% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(0);
    }
}

.navbar-nav {
    .notify_css {
        padding-right: 10px !important;
    }
}

.navbar-nav {
    .faq_css {
        padding-right: 16px !important;

        div:first-child {
            margin-right: 10px !important;
        }
    }
}

.dropdown-item img {
    margin-right: 10px !important;
    margin-bottom: 4px;
}