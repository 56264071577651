.settings {
  background-color: var(--white);

  .settings-item {
    background-color: var(--white);
    border-bottom: 1px solid #e8e8e8;

    .settings-header {
      margin-bottom: 0;

      .settings-button {
        color: var(--black);
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        background-color: var(--white);
        align-items: center;
        border: 0;
        border-radius: 0;
        display: flex;
        overflow-anchor: none;
        padding: 19px 32px 19px 32px;
        position: relative;
        text-align: left;
        width: 100%;
        justify-content: space-between;

        > :nth-child(3) {
          flex-shrink: 0;
          margin-left: auto;
        }
      }

      .settings-heading {
        font-size: 16px;
        text-transform: uppercase;
        font-weight: 500;
        color: #515253;
        font-family: "Lato";
      }
      .settings-new-icon {
        width: 24px;
        height: 24px;
      }
    }
  }
}
.onboarding-errors {
  background-color: #f04b4b;
  font-family: "Lato";
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  border-radius: 6px;
  width: 87px;
  height: 31px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 1.5rem 0 auto;
}
