
.not-found-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.not-found-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
