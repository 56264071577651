.reports-container {
  margin: 1rem;
}
.reports-title-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.custom-btn-wrapper {
  box-shadow: 0px 1px 3px 0px #0000001a;
  box-shadow: 0px 1px 2px 0px #00000033;
  font-family: "Lato";
  font-size: 11px;
  font-weight: 400;
  line-height: 14px;
  width: 145px;
  height: 36px;
  background: #2773b1;
  border: none;
  border-radius: 3px;
  color: #fff;
}
.table-section-wrapper {
  background: #fff;
  padding: 1.5rem;
}
.tab-change-btn-wrapper {
  background-color: #fff;
  // border: 1px solid #d9d9d9;
  font-family: "Lato";
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  color: #000;
  width: 125px;
  height: 43px;
  border-top: 0.5px solid #d9d9d9;
  border-bottom: 0.5px solid #d9d9d9;
  &:active,
  &.active {
    background: #eef6fd;
  }
}
.report-table {
  background: #fff;
  width: 100%;
  border: 1px solid #e2e8f0;
  box-shadow: 0px 4px 6px 0px #00000017;
  thead,
  tbody,
  tfoot,
  tr,
  td,
  th {
    padding: 1.1rem 1rem;
  }
  tr {
    border-bottom: 1px solid #d9d9d9;
  }
  th {
    font-family: "Lato";
    font-size: 14px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: -0.025em;
    text-align: left;
    color: #888888;
    border-top: 1px solid #d9d9d9;
  }
  td {
    font-family: "Lato";
    font-size: 14px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: -0.025em;
    text-align: left;
    color: #6e6e6e;
    a {
      font-weight: 600;
      color: #2773b1;
    }
    p {
      color: #000;
      font-weight: 600;
      margin: 0;
    }
  }
  td:hover {
    overflow: visible;
    white-space: normal;
  }
}
.cus-report-font-wrapper {
  font-weight: 600 !important;
  color: #2773b1 !important;
  cursor: pointer;
}
.action-btn-wrapper {
  height: 38px;
  button {
    color: #757575;
    border: none;
    font-family: "Lato";
    font-size: 11px;
    font-weight: 500;
    line-height: 14px;
    text-align: center;
    text-transform: uppercase;
    padding: 0.35rem 0.5rem;
    background: #fff;
  }
}
.run-btn-wrapper {
  button {
    color: #fff;
    border: none;
    font-family: "Lato";
    font-size: 11px;
    font-weight: 400;
    line-height: 14px;
    text-align: center;
    text-transform: uppercase;
    background: #2773b1;
    border-radius: 3px;
    height: 36px;
    width: 78px;
  }
}
.action-column-wrapper {
  display: flex;
  justify-content: start;
  align-items: center;
}
.date-selector-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: end;
  input {
    height: 40px;
  }
  label {
    font-family: "Lato";
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    span {
      color: red;
    }
  }
}
.filter-prepared-text {
  color: #2773b1 !important;
  font-family: "Lato";
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  margin: 0.5rem 0 0 !important;
}
.disabled-btn-wrapper {
  border: 1px solid #ced4da;
  color: #ced4da;
  font-family: "Lato";
  font-size: 11px;
  font-weight: 400;
  line-height: 14px;
  text-align: center;
  padding: 0.75rem 3rem;
  border-radius: 3px;
  margin: 0 0rem 0 0;
  background: #fff;
  border-radius: 3px;
}
.disabled-export-csv-btn {
  font-family: "Lato";
  font-size: 11px;
  font-weight: 400;
  line-height: 14px;
  text-align: center;
  padding: 0.75rem 2rem;
  border-radius: 3px;
  color: #fff;
  border-radius: 3px;
  border: 1px solid #ced4da;
  color: #ced4da;
  cursor: not-allowed !important;
}
.reports-tooltip {
  // width: 6%;
  .rc-tooltip-content {
    width: 100%;
  }
  .rc-tooltip-inner {
    background: #212529 !important;
    border-radius: 8px !important;
    color: #fff;
    text-align: center !important;
  }
  .rc-tooltip-placement-right .rc-tooltip-arrow {
    border-right-color: #212529 !important;
  }
}
.btn-apply-filter {
  height: 40px;
  width: 165px;
  padding: 0 !important;
  box-shadow: 0px 1px 3px 0px #0000001a;
  box-shadow: 0px 1px 2px 0px #00000033;
}
.cus-font-weight-wrapper {
  font-weight: 400 !important;
  color: #6e6e6e !important;
}
.border-right-class-cus {
  border-right: 0.5px solid #d9d9d9;
  border-left: none;
}
.border-left-class-cus {
  border-left: 0.5px solid #d9d9d9;
  border-right: none;
}
.reports-filter-modal-wrapper {
  .modal-dialog {
    min-width: 520px;
    width: 100%;
  }
}
.run-btn-wrapper {
  margin: 0 0.75rem 0 0;
}
.reports-profile-manage-wrapper {
  height: 38px;
  margin: 0 0.25rem 0 0;
  button {
    color: #757575;
    border: none;
    font-family: "Lato";
    font-size: 11px;
    font-weight: 500;
    line-height: 14px;
    text-align: center;
    text-transform: uppercase;
    padding: 0.35rem 0.5rem 0.35rem 0;
    background: #fff;
  }
}
.select-reseller-wrapper {
  .css-9gakcf-option {
    background-color: #fff;
    color: #000;
  }
}
.select-client-wrapper {
  .css-9gakcf-option {
    background-color: #fff;
    color: #000;
  }
}
.client-selection-wrapper {
  margin: 1rem 0;
}
.date-selector {
  width: 45%;
}
.date-selector-btn {
  margin: 0.5rem 0;
  button {
    width: 100%;
  }
}
// Responsive
@media screen and (max-width: 1024px) {
  .report-table-scroll {
    overflow-x: scroll;
  }
  .reports-filter-modal-wrapper {
    .modal-dialog {
      min-width: auto;
      width: 95%;
    }
  }
  // .run-btn-wrapper {
  //   margin: 0;
  // }
}
@media screen and (max-width: 768px) {
  .reports-container {
    margin: 0.5rem;
  }
  .table-section-wrapper {
    padding: 0.75rem;
  }
  .action-btn-wrapper {
    display: flex;
    justify-content: space-between;
  }
  .report-data-column-wrapper {
    display: flex;
  }
  .reports-profile-manage-wrapper {
    display: flex;
    justify-content: space-between;
  }
}
@media screen and (max-width: 700px) {
  .tab-section-cus-wrapper {
    .btn-pto-wrapper {
      justify-content: start;
    }
  }
}
@media screen and (max-width: 600px) {
  .reports-title-wrapper {
    .board-header {
      padding: 1rem 0.5rem;
    }
  }
  .tab-section-cus-wrapper {
    .btn-pto-wrapper {
      justify-content: start;
    }
  }
  .disabled-btn-wrapper {
    width: 100%;
  }
  .disabled-export-csv-btn {
    width: 100%;
  }
  .date-selector-wrapper {
    // flex-direction: column;
    // grid-gap: 0.5rem;
    // .date-selector {
    //   width: 100%;
    // }
    .date-selector-btn {
      width: 100%;
      margin: 0.5rem 0;
    }
  }
  .btn-apply-filter {
    width: 100%;
  }
}
