.drop-zone {
  height: 150px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: "Quicksand", sans-serif;
  font-weight: 500;
  font-size: 20px;
  cursor: pointer;
  color: #cccccc;
  border: 1px dashed #2773b1;
  border-radius: 10px;
}

.drop-zone--over {
  border-style: solid;
}

.drop-zone__input {
  display: none;
}

.drop-zone__thumb {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
  background-color: #cccccc;
  background-size: cover;
  position: relative;
}

.drop-zone__thumb::after {
  content: attr(data-label);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 5px 0;
  color: #ffffff;
  background: rgba(0, 0, 0, 0.75);
  font-size: 14px;
  text-align: center;
}

.upload_document_modal {
  .modal-dialog {
    @media (min-width: 575px) {
      max-width: 60%;
      top: 100px;
    }

    .modal-footer {
      border: 0px !important;
    }
  }
}

.header_bg_table {
  width: 100%;

  td {
    word-wrap: break-word;
  }
}

.table_mobile_scroll {
  @media (max-width: 767px) {
    overflow-x: scroll;
  }

  tr {
    td:first-child {
      @media (max-width: 515px) {
        white-space: nowrap;
      }
    }

    td {
      vertical-align: middle;
    }
  }
}

.drop-file-input {
  position: relative;
  height: 200px;
  border-radius: 20px;
  height: 89px;
  background: #ffffff;
  border: 1px dashed #2773b1;
  border-radius: 6px;
}

.drop-file-input input {
  opacity: 0;
  position: absolute;
  top: 0px;
  bottom: -150px;
  /* left: 0; */
  width: 100%;
  height: 89px;
  cursor: pointer;
}

.drop-file-input:hover,
.drop-file-input.dragover {
  opacity: 0.6;
}

.drop-file-input__label {
  text-align: center;
  height: inherit;
  font-weight: 600;
  padding: 10px;
}

.drop-file-input__label img {
  width: 100px;
}

.drop-file-preview {
  margin-top: 30px;
}

.drop-file-preview p {
  font-weight: 500;
}

.invalid_feedback {
  color: red;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
}

.drop-file-preview__title {
  margin-bottom: 20px;
}

.drop-file-preview__item {
  position: relative;
  display: flex;
  margin-bottom: 10px;
  background-color: var(--input-bg);
  padding: 15px;
  border-radius: 20px;
}

.drop-file-preview__item img {
  width: 50px;
  margin-right: 20px;
}

.drop-file-preview__item__info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.drop-file-preview__item__del {
  background-color: var(--box-bg);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  box-shadow: var(--box-shadow);
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.drop-file-preview__item:hover .drop-file-preview__item__del {
  opacity: 1;
}

.onClick_file_btn {
  opacity: 0;
  position: absolute;
  top: 0px;
  bottom: -150px;
  width: 100%;
  height: 89px;
  cursor: pointer;
}

.dropfile_realtive {
  position: relative;
}

.tab-head,
.edit-modal-header {
  font-size: 18px;
  font-weight: 700;
  color: #6e6e6e;
}

.document-td {
  color: #27A2DF;
  cursor: pointer;
}

.datepicker-payscheme {
  height: 39px !important;
}

.confirmation_modal {
  .modal-header {
    box-shadow: none;
    justify-content: center;

    .modal-title {
      font-size: 18px;
    }

    .btn-close {
      display: none;
    }
  }
}