.sign-action-btn-wrapper {
  button {
    font-family: "Lato";
    font-size: 11px !important;
    font-weight: 400;
    line-height: 14px !important;
    text-transform: uppercase;
    background: #2773b1 !important;
    border-radius: 3px;
    color: #fff;
    width: 78px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
  }
}
.action-doc-btn-wrapper {
  button {
    opacity: 0.5;
  }
}
