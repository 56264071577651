.progress-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  //   border-left: 1.01px solid #d1d5db;
  //   border-bottom: 1.01px solid #d1d5db;
  //   border-top: 1.01px solid #d1d5db;
  border: 1.01px solid #d1d5db;
  border-radius: 10px;

  .progress-step {
    display: flex;
    align-items: center;
    width: fit-content;
    justify-content: space-between;

    .icon-label-container {
      display: flex;
      align-items: center;
      padding: 1rem;
      gap: 16px;

      .step-icon {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        font-size: 16px;
        font-weight: bold;
        background-color: #fff;
        border: 2px solid #d1d5db;
        color: #d1d5db;

        &.step-completed {
          border-color: #2773b1;
          color: #2773b1;

          img {
            width: 40px;
            height: 40px;
          }
        }
      }

      .step-label {
        margin-left: 8px; // Spacing between the icon and label
        font-size: 14px;
        font-family: "Lato";
        color: #c4c4c4;

        &.step-completed {
          color: #2773b1;
          font-weight: 700;
        }
      }
    }

    .arrow-container {
      margin-left: 0px; // Space between the icon-label container and the arrow

      .right-arrow {
        width: 22px;
        height: 80px;
        color: #d1d5db; // Arrow color for inactive steps
      }
    }
  }
}
.step-completed-label {
  font-family: "Lato";
  font-size: 16px !important;
  font-weight: 700;
  line-height: 19.6px;
  color: #2773b1;
  font-size: 14px;
}
.progress-bar-container {
  box-shadow: 0px 1px 2px 0px #0000000d;
  box-shadow: 0px 0px 0px 1px #1118270d;
  padding: 1.5rem;
  @media (max-width: 600px) {
    padding: 0;
  }
}
@media screen and (max-width: 1024px) {
  .progress-bar-container {
    padding: 0.5rem 0.75rem;
    overflow-x: scroll;
  }
  .progress-bar {
    min-width: fit-content;
    .progress-step {
      width: auto;
      .icon-label-container {
        padding: 0.5rem 2rem;
      }
    }
  }
}
