.banking_container {
  .form-check-input[type="checkbox"] {
    border: 1px solid;
  }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #198754 !important;
    box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}
  thead {
    tr {
      background-color: #ebf2f8;
    }
  }
  tbody {
    tr {
      background-color: #ffffff;
    }
    td {
      vertical-align: middle;
      .edit_delete_container {
        div {
          p {
            margin-bottom: 0px;
          }
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }

  .btn_clr_white {
    color: white;
    font-size: 0.9rem;

    &:hover {
      color: white;
    }
  }
  .mobile_accounts_table {
    border: 0.5px solid #e8e8e8;

    .table_xs_row {
      div:first-child {
        background: #ebf2f8;
        padding: 15px 10px;
        font-family: "Lato";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #000000;
        flex: none;
        order: 0;
        flex-grow: 0;
      }
      div:nth-child(2) {
        font-family: "Lato";
        padding: 15px 10px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #515253;
        flex: none;
        order: 1;
        flex-grow: 0;
      }
    }
    .mobile_icon {
      padding: 15px 10px;
      span {
        font-family: "Lato";
        font-style: normal;
        font-weight: 800;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: #515253;
        flex: none;
        order: 1;
        flex-grow: 0;
      }
    }
  }
  .custom-bank-table{
    @media (max-width:768px) {
      display: none !important;
    }
  }
}
