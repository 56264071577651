.login-footer {
    background: var(--primary);
    height: 70px;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: var(--white);
    a{
        text-decoration: none;
        color: #fff;
    }
}