.select-check-box {
  margin: 0 16px 0 0;
  width: 18px;
  height: 18px;
  //   outline: 1px solid #6e6e6e;
}
.all-employee-container {
  padding: 16px;
}
.approve-all-button {
  font-family: "Lato";
  font-size: 11px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: center;
  color: #fff;
  border-radius: 3px;
  padding: 10px 45px;
  box-shadow: 0px 1px 3px 0px #0000001a;
  box-shadow: 0px 1px 2px 0px #00000033;
}
.deny-all-button {
  font-family: "Lato";
  font-size: 11px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: center;
  border-radius: 3px;
  padding: 10px 58px;
  background: #fff;
  box-shadow: 0px 1px 3px 0px #0000001a;
  box-shadow: 0px 1px 2px 0px #00000033;
}
.button-wrapper {
  display: flex;
  grid-gap: 20px;
}
.select-employee-heading {
  font-family: "Lato";
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  color: #515253;
}
// .select-employee-names {

// }
.select-individual-employee {
  padding: 10px 16px;
  margin: 10px 0;
  background: #f9f9f9;
  border-bottom: 1px solid #d8e1e8;
  label {
    font-family: "Lato";
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #000;
  }
  label::after {
    content: "";
    border-right: 1px solid #aaaaaa;
    padding: 0 0 0 12px;
  }
  p {
    font-family: "Lato";
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #515253;
    margin: 0;
    padding: 0 12px;
  }
}
.approve-and-deny-btn {
  grid-gap: 9px;
}
.approve-btn {
  font-family: "Lato";
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  background: #e8e8e8;
  border: none;
  height: 30px;
  padding: 0px 23px;
  color: #515253;
  border-radius: 16px;
}
.active-approve-btn-wrapper {
  background: #fff8e7;
}
.deny-btn {
  font-family: "Lato";
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  border: 0.8px solid #f04b4b;
  background: #fff;
  padding: 0 24px;
  color: #515253;
  border-radius: 18px;
}
.active-btn {
  background: #2773b1;
  border: 1px solid #2773b1;
}
.disable-btn {
  background: #c5c5c5;
  border: 1px solid #c5c5c5;
}
.deny-active-btn {
  border: 1px solid #2773b1;
  color: #2773b1;
}
.deny-disable-btn {
  border: 1px solid #c5c5c5;
  color: #aaaaaa;
}
.modal-title-cus {
  text-align: center;
  width: 75%;
  margin: auto;
  padding: 40px 0;
  font-family: "Lato";
  font-size: 24px !important;
  font-weight: 700 !important;
  line-height: 36px;
  letter-spacing: 0em;
}
.modal-pay-cus-header {
  padding: 0;
  justify-content: center;
  box-shadow: none;
}
.modal-pay-cus-body {
  border-top: 1px solid #f3f3f3;
  padding: 32px 0;
}
.select-all-employee-container {
  h4 {
    font-family: "Lato";
    font-size: 20px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    padding: 19px 16px 0;
  }
}
.modal-btn-wrapper {
  button {
    text-transform: capitalize;
  }
}
.button-gap {
  grid-gap: 24px;
}
.collapse-btn {
  border: 1px solid #2773b1;
  border-radius: 16px;
  font-family: "Lato";
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  background: #fff;
  padding: 6px 16px;
  color: #515253;
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 6px;
  max-width: 105px;
  min-width: 104px;
  img {
    width: 20px;
    height: 20px;
  }
}
.server-side-table .table .thead-dark th {
  background-color: #fff;
  border-bottom: 1px solid #d9d9d9 !important;
  font-family: "Lato";
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  color: #515253 !important;
}
.server-side-table .table .thead-dark tr {
  background-color: #fff;
  font-family: "Lato";
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  color: #6e6e6e !important;
}
.pay-filter-title {
  background: #f9f9f9;
  h5 {
    font-family: "Raleway";
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0em;
    color: #646569;
    text-transform: uppercase;
    margin: 0;
  }
}
.pay-filter-dropdown {
  p {
    font-family: "Lato";
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.01em;
    margin: 0 0 6px;
    color: #515253;
  }
  .css-b62m3t-container {
    min-width: 230px;
    max-width: 240px;
  }
  .css-tlfecz-indicatorContainer {
    background: #edf1f2;
  }
  .css-1okebmr-indicatorSeparator {
    margin: 0;
    width: 0;
  }
  .css-tj5bde-Svg {
    color: #1c1b1f;
  }
}
.search-filter-wrapper {
  border-bottom: 1px solid #d9d9d9;
}
.filter-detail-wrapper {
  display: flex;
  margin: 0 24px;
  justify-content: space-between;
}
.filter-detail-cards {
  box-shadow: 0px 0px 6px 0px #0000001a;
  border-radius: 4px;
  padding: 14px 38px;
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    font-family: "Lato";
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    color: #515253;
    margin: 7px 0;
  }
  h5 {
    font-family: "Lato";
    font-size: 20px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0em;
    margin: 7px 0;
    color: #515253;
  }
}
.report-button-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 0 24px 30px;
  position: relative;
}
.export-button {
  border: 1px solid #2773b1;
  color: #2773b1;
  box-shadow: 0px 1px 3px 0px #0000001a !important;
  margin: 0px;
  text-transform: uppercase;
  font-family: "Lato";
  font-size: 11px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  padding: 10px 16px;
  height: 35px;
}
.go-report-button {
  background: #2773b1;
  color: #fff !important;
  box-shadow: 0px 1px 2px 0px #00000033;
  text-transform: uppercase;
  font-family: "Lato";
  font-size: 11px;
  font-weight: 400;
  line-height: 14px;
  padding: 10px 16px;
  height: 35px;
  img {
    padding: 0 0px 0 8px;
  }
  &:hover {
    color: #fff;
  }
}
.emp-action-btn {
  button {
    background: none;
    border: none;
  }
}
.modal-tile-expand {
  font-family: "Lato";
  font-size: 24px !important;
  font-weight: 400 !important;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  color: #292d32;
}
.modal-expand-header {
  background-color: #edf1f2;
  justify-content: start;
  padding: 18px 20px;
  border-bottom: 1px solid #ebebeb;
}
.modal-expand-footer {
  border-top: 1px solid #f3f3f3;
  padding: 18px 20px;
}
.modal-expand-content-title {
  padding: 0px 20px 24px;
  h5 {
    font-family: "Lato";
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    color: #292d32;
    margin: 0px 0 14px;
  }
  p {
    font-family: "Lato";
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    color: #6e6e6e;
    margin: 0;
  }
}
.modal-main-wrapper {
  --bs-modal-width: 600px;
  .modal-content {
    width: 100%;
  }
}
.modal-expand-body {
  padding: 32px 0 8px;
}

.width-wrapper-cus {
  width: 90%;
}
.text-pending {
  color: #f5bc00 !important;
}
.text-approved {
  color: #27c24c !important;
}
.text-unapproved {
  color: #f04b4b !important;
}
.text-editingRequest {
  color: #f04b4b !important;
}
.modal-play-header {
  background-color: #fff;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  border-bottom: 1px solid #d9d9d9;
}
.modal-tile-play {
  font-family: "Lato";
  font-size: 24px !important;
  font-weight: 700 !important;
  line-height: 29px;
  letter-spacing: 0em;
  color: #6e6e6e;
}
.modal-play-close {
  cursor: pointer;
}
.modal-date-wrapper {
  display: flex;
  justify-content: center;
  p {
    font-family: "Lato";
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    color: #6e6e6e;
    margin: 0 0 10px;
  }
}
.modal-play-body {
  padding: 22px 0 8px;
  width: 67%;
  margin: auto;
}
.modal-play-content-wrapper {
  display: flex;
  flex-direction: column;
  h5 {
    font-family: "Lato";
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    color: #6e6e6e;
  }
}
.modal-clock-wrapper {
  display: flex;
  justify-content: space-between;
}
.modal-play-clock {
  display: flex;
  align-items: center;
  width: 40%;
  justify-content: space-between;
  h5 {
    font-family: "Lato";
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    color: #6e6e6e;
    margin: 0;
    width: 70%;
  }
}
.modal-play-main-wrapper {
  --bs-modal-width: 71% !important;
}
.modal-play-btn-wrapper {
  display: flex;
  justify-content: space-between;
  margin: 0 0 32px;
  button {
    border-radius: 3px;
    background: #eae9e9;
    font-family: "Lato";
    font-size: 11px;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0em;
    color: #6e6e6e;
    padding: 12px;
    border: none;
    // box-shadow: 0px 1px 3px 0px #0000001a;
    box-shadow: 0px 1px 2px 0px #00000033;
  }
}
.total-hours-wrapper {
  display: flex;
  align-items: center;
  margin: 35px 0 16px;
  h5 {
    font-family: "Lato";
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    margin: 0;
    color: #6e6e6e;
  }
  p {
    font-family: "Lato";
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0;
    color: #6e6e6e;
    padding: 0 3.5rem;
  }
}
// styles.scss

.input-disabled-wrapper {
  display: flex;
  flex-direction: column;
  margin: 14px 0 16px;

  input[type="text"] {
    background: #edf1f2;
    border: 1px solid #e3eaed;
    border-radius: 7px;
    padding: 8px 12px;
    &::placeholder {
      font-family: "Lato";
      font-size: 16px;
      font-weight: 700;
      line-height: 19px;
      letter-spacing: 0em;
      color: #aea7a7;
    }
    &:focus-visible {
      outline: none;
    }
  }
}
.text-area-wrapper {
  margin: 24px 0;
}
.note-input-group {
  align-items: center;
  border-radius: 7px;
  textarea {
    height: 100px;
    resize: none;
  }
}
.input-group-addon {
  background: #edf1f2;
}
.dropdown-wrapper {
  display: flex;
  align-items: center;
  width: 194px;
  height: 100px;
  justify-content: center;
  background: #edf1f2;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
}
@media screen and (max-width: 1024px) {
  .search-filter-wrapper {
    flex-direction: column;
  }
  .pay-filter-dropdown {
    margin: 1rem 0;
    .css-b62m3t-container {
      min-width: auto;
      max-width: none;
    }
  }
  .filter-detail-wrapper {
    flex-direction: column;
  }
  .filter-detail-cards {
    margin: 1rem 0;
    padding: 14px;
  }
  .select-individual-employee {
    flex-direction: column;
  }
  .select-all-employee-container {
    h4 {
      line-height: 26px;
    }
    p {
      font-size: 12px;
    }
  }
  .approve-all-button {
    padding: 10px 30px;
  }
  .select-employee-heading {
    font-size: 16px;
  }
  .select-check-box {
    width: auto;
    height: auto;
    margin: 2px 8px 0 0;
  }
  .select-individual-employee {
    label {
      font-size: 14px;
    }
    .button-wrapper {
      margin: 1.5rem 0 0;
    }
  }

  .approve-and-deny-btn {
    flex-wrap: wrap;
    justify-content: center;
    grid-gap: 25px;
  }
  .collapse-btn {
    padding: 0.5rem 1rem;
  }
  .deny-btn {
    padding: 0.5rem 24px;
  }
  .approve-btn {
    padding: 0.5rem 24px;
    height: auto;
  }
  .modal-wrap-class {
    flex-wrap: wrap;
  }
  .modal.show .modal-dialog {
    justify-content: center;
  }
  // .modal-main-wrapper {
  //   --bs-modal-width: auto;
  // }
}
.filter-card-last-section {
  box-shadow: 0px 0px 6px 0px #0000001a;
  border-radius: 4px;
  padding: 14px 38px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 55%;
  justify-content: space-between;
  p {
    font-family: "Lato";
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    color: #515253;
    margin: 7px 0;
  }
  h5 {
    font-family: "Lato";
    font-size: 20px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0em;
    margin: 7px 0;
    color: #515253;
    display: flex;
    align-items: center;
    img {
      margin: 0 6px;
    }
  }
}
.new-filter-cards {
  text-align: center;
}
.timecard-approved-wrapper {
  border: 1px solid #d9d9d9;
  height: 50px;
}
.modal-approve-content-title {
  padding: 0px 20px 24px;

  p {
    font-family: "Lato";
    font-size: 24px;
    font-weight: 400;
    line-height: 34px;
    letter-spacing: 0em;
    color: #646569;
    margin: 0;
  }
}
.manage-modal-btn {
  font-family: "Lato";
  font-size: 11px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: center;
  border-radius: 3px;
  padding: 10px 12px;
  color: #fff;
  background-color: #2773b1;
  box-shadow: 0px 1px 3px 0px #0000001a;
  box-shadow: 0px 1px 2px 0px #00000033;
  border: 1px solid #2773b1;
  text-transform: uppercase !important;
}
.pending-alert-text {
  font-family: "Lato";
  font-size: 13px;
  font-weight: 600;
  line-height: 15.6px;
  text-align: left;
  color: #ffc130;
  margin: 0;
  padding: 10px 0 0;
}
.completed-alert-text {
  font-family: "Lato";
  font-size: 13px;
  font-weight: 600;
  line-height: 15.6px;
  text-align: left;
  color: #33b033;
  margin: 0;
  padding: 10px 0 0;
}
.info-text-wrapper {
  display: flex;
  width: 60%;
  img {
    width: 20px;
    height: 20px;
    margin: 3px 3px 3px 0;
  }
  p {
    font-family: "Lato";
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: #6e6e6e;
    margin: 0;
    span {
      font-weight: 700;
    }
  }
}
.info-btn-wrapper {
  display: flex;
  justify-content: space-between;
  grid-gap: 1rem;
}
.go-to-reports-sub-section {
  position: absolute;
  display: flex;
  right: 0;
  flex-direction: column;
  top: 67%;
  z-index: 1000;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  background-color: #fff;
  border: 1px solid #ccc;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-clip: padding-box;
  a {
    font-weight: 400;
    line-height: 1.52857143;
    color: #5f5f5f;
    font-family: "lato";
  }
}
.go-to-report-lab {
  border-bottom: 1px solid #e5e5e5;
  padding: 3px 0px 8px;
  text-align: center;
}
.go-to-report-summary {
  padding: 8px 0px 3px;
  text-align: center;
}
@media (max-width: 1024px) {
  .filter-card-last-section {
    width: 100%;
  }
  .report-button-wrapper {
    flex-direction: column;
  }
  .info-text-wrapper {
    width: 100%;
    align-items: center;
  }
  .info-btn-wrapper {
    margin: 1rem 0;
    justify-content: end;
  }
}
@media (max-width: 768px) {
  .report-button-wrapper {
    flex-direction: column;
    justify-content: center;
    grid-gap: 25px;
  }
  .export-button {
    margin: 0;
  }
  .modal-play-body {
    width: 80%;
  }
  .modal-date-wrapper {
    justify-content: start;
  }
  .modal-play-clock {
    width: 100%;
    margin: 1rem 0;
    h5 {
      font-size: 18px;
    }
  }
  .modal-clock-wrapper {
    flex-direction: column;
  }
  .total-hours-wrapper {
    margin: 14px 0 16px;
    justify-content: space-between;
    p {
      padding: 0 0rem 0 1.5rem;
    }
  }
  .modal-play-btn-wrapper {
    flex-direction: column;
    button {
      margin: 0.5rem 0;
    }
  }
  .dropdown-wrapper {
    width: 120px;
  }
  .modal-play-main-wrapper {
    --bs-modal-width: 90% !important;
  }
  .modal.show .modal-dialog {
    width: 95%;
  }
  .info-btn-wrapper {
    flex-direction: column;
  }
  .filter-card-last-section {
    flex-direction: column;
    padding: 14px;
  }
  .timecard-approved-wrapper {
    width: 100%;
    height: 0;
  }
  .resp-text-info-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .new-filter-cards {
    h5 {
      font-size: 14px;
    }
  }
  .go-to-reports-sub-section {
    width: 90%;
    top: 85%;
    left: 50%;
    transform: translateX(-50%);
  }
}
