.profile_container {
  width: 100%;
  padding: 0px 20px;
  // padding-bottom: 25px;
  background-color: #eae9e9;

  .tab-content > .active {
    background-color: #ffffff;
    padding: 16px;
    padding-bottom: 20px;
  }

  .client_details {
    font-family: "Raleway";
    margin-left: 20px !important;

    h1 {
      padding-top: 5px;
      font-family: "Raleway";
      font-size: 22px;
      font-weight: 500;
      line-height: 26px;
      margin-bottom: 0;
    }

    p {
      font-family: "Raleway";
      font-size: 18px;
      font-weight: 300;
      line-height: 38px;
      margin: 0;
    }
  }

  @media (min-width: 1400px) {
    .container-xxl,
    .container-xl,
    .container-lg,
    .container-md,
    .container-sm,
    .container {
      max-width: 1320px;
      margin-left: 0;
    }
  }

  .nav-tabs {
    flex-wrap: nowrap;
    border: 0px;

    li {
      white-space: nowrap;

      @media (min-width: 1050px) {
        flex: 1;

        button {
          width: 100%;
        }
      }
    }

    @media (min-width: 767px) and (max-width: 1200px) {
      flex-wrap: wrap;
    }

    @media (max-width: 1400px) {
      flex-wrap: nowrap !important;
      overflow-x: scroll !important;
      overflow-y: hidden !important;

      // ::-webkit-scrollbar {
      //   display: none;
      // }

      &::-webkit-scrollbar {
        width: 20px;
        height: 6px;
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 20px;
      }
    }

    .nav-link {
      padding: 10px 15px 10px 16px;
      background: #edf1f2;
      border: 0.5px solid #dde6e9;
      border-top-right-radius: 0px;
      border-top-left-radius: 0px;
      color: #515253;
      font-weight: bold;
      text-transform: capitalize;
      font-size: 0.9rem;
    }

    .active {
      background: #fff;
    }
  }

  .form-check-label {
    // font-size: 0.9rem;
    font-size: 16px;
  }

  .tabs_main_heading {
    height: 18px;
    font-family: "Lato";
    font-style: normal;
    font-weight: 700;
    // font-size: 1rem;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #6e6e6e;
  }

  p {
    font-size: 14px;
  }

  .form-label {
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    // font-size: 0.9rem;
    font-size: 16px;

    line-height: 19px;
    color: #000000;
  }

  input {
    padding: 10px 16px 8px 24px;
    border: 1px solid #e8e8e8;

    &::placeholder {
      height: 17px;
      font-family: "Lato";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #aaaaaa;
    }

    &:focus {
      border: 1px solid #e8e8e8;
    }
  }

  .form-check-input[type="radio"],
  .form-check-input[type="checkbox"] {
    padding: 0px;
  }

  .form-select {
    background-image: url("../../assets/images/Vector.svg") !important;
    background-size: inherit !important;
    padding: 10px 16px 8px 24px;
    border: 1px solid #e8e8e8;
    appearance: none !important;
    font-size: 0.9rem !important;
    line-height: 1.5;

    &:focus {
      border: 1px solid #e8e8e8;
      box-shadow: none;
    }
  }

  .header_bg_table {
    border-radius: 5px 5px 0px 0px;
    border: 0.5px solid #e8e8e8;

    thead {
      tr {
        background-color: #ebf2f8;

        th {
          font-family: "Lato";
          font-style: normal;
          font-weight: 500;
          font-size: 0.9rem;
          line-height: 19px;
          color: #000000;
        }
      }
    }

    tbody {
      tr {
        background-color: #ffffff;

        td {
          font-family: "Lato";
          font-style: normal;
          font-weight: 400;
          font-size: 0.8rem;
          line-height: 17px;
          color: #515253;
          white-space: nowrap;

          p {
            margin-bottom: 0px;
          }

          .edit_delete_container {
            align-items: center;
            justify-content: center;

            svg {
              font-size: 0.9rem;
            }

            div {
              &:hover {
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }

  .save_btn {
    border: 2px solid #2773b1;
    background: #2773b1;
    border-radius: 5px;
    font-family: "Lato";
    font-style: normal;
    font-weight: 800;
    // font-size: 0.9rem;
    font-size: 16px;
    text-align: center;
    color: #ffffff;
    width: 200px;

    @media (max-width: 515px) {
      width: inherit;
    }
  }

  @media (max-width: 515px) {
    padding: 0px !important;
  }
}

.grey_text_heading {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #6e6e6e;
}

.prev_next_user_container {
  text-align: right;
  display: flex;
  justify-content: end;
  align-items: start;
  span {
    .btn {
      padding: 0 1.5rem 9.5px !important;
    }
  }
  // @media (min-width: 767px) and (max-width: 1300px) {
  //   display: flex;
  //   flex-direction: column;
  //   span {
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;
  //   }
  // }
}

.form-control {
  // font-size: 0.9rem;
  font-size: 14px;
}

.was-validated .form-control.invalid_input:valid,
.form-control.is-valid {
  border-color: red !important;
}

// .invalid_input{
//   border-color: red !important;
// }
.normal_input {
  border-color: #ced4da !important;
}

.was-validated .form-control.is-valid {
  border-color: green !important;
}

.form-control.is-invalid,
.was-validated .form-control .is-invalid {
  border-color: var(--red) !important;
}

.upload_document_modal {
  .modal-dialog {
    .datepicker-payscheme {
      padding: 10px 16px 8px 24px !important;
      height: 35px !important;
    }
  }
}

.profile-right-btns {
  display: flex;
  flex-direction: column;
}

.revert {
  display: flex;
  align-items: end;
  justify-content: end;
  margin: 0 22px 0 0;
}

.revert-img {
  margin-top: -2px;
}

.revert_btn {
  background: #f05050;
  border: 1px solid #f05050;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2), 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  color: #fff;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: bold;
  height: 35px;
  width: 124px;
  padding: 4px 4px !important;
  font-weight: 400;

  &:hover,
  &:active,
  &:visited,
  &:focus {
    background: #f05050;
    border: 2px solid #f05050;
    color: #fff;
    font-weight: 400;
  }
}

.profile-border {
  border-left: 6px solid #2773b1;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .d-md-table {
    display: block !important;
  }
}

.tab_container {
  .nav {
    flex-wrap: nowrap;
  }

  .nav-item {
    text-align: center;
    flex: 1 1;

    .nav-link {
      padding: 10px 15px 10px 16px;
      background: #edf1f2;
      border: 0.5px solid #dde6e9;
      border-top-right-radius: 0px;
      border-top-left-radius: 0px;
      color: #515253;
      font-weight: bold;
      text-transform: capitalize;
      font-size: 0.9rem;
    }

    .dropdown-toggle::after {
      margin-left: 6px;
      vertical-align: 1px;
      border-top: 6px solid;
      border-right: 5px solid transparent;
      border-bottom: 0;
      border-left: 5px solid transparent;
    }

    .nav-link.active {
      background: #fff;
    }

    .dropdown-item:active {
      color: var(--bs-dropdown-link-hover-color);
      background-color: var(--bs-dropdown-link-hover-bg);
    }
  }
}

.search_btn_profile {
  border: 2px solid #2773b1;
  background: #2773b1;
  border-radius: 5px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  width: 120px;
  height: 34px;
  padding: 4px;

  &:disabled {
    color: #fff !important;
    background: #2773b1 !important;
  }
  @media (max-width: 400px) {
    width: auto;
  }
}

.search_cancel_btn {
  background: #ffffff;
  border: 1px solid #515253;
  border-radius: 5px;
  color: #515253;
  font-weight: bold;
  width: 120px;
  height: 34px;
  padding: 3px 14px !important;
  font-size: 16px;

  &:hover {
    background: #ffffff;
    border: 1px solid #515253;
    color: #515253;
  }
  @media (max-width: 400px) {
    width: auto;
  }
}

.search-form-profile {
  padding: 6px !important;
  width: 250px;
  height: 34px;

  @media (max-width: 1000px) {
    width: auto;
  }

  @media (max-width: 450px) {
    width: 96%;
  }
}

.profile_search {
  margin-right: 22px;
  display: flex;
  justify-content: end;

  @media (max-width: 400px) {
    margin-right: 0px;
  }

  @media (min-width: 750px) and (max-width: 820px) {
    margin-top: 17px !important;
  }
  @media (min-width: 821px) and (max-width: 950px) {
    margin-top: 10px !important;
  }
}

.profile_search_form {
  display: flex;
}

.profile-search-cancel {
  position: relative;
  input {
    border-radius: 5px !important;
  }
  .input-group-text {
    background-color: inherit;
    border: 0px;
    cursor: pointer;
    color: #4b545a;
    position: absolute;
    right: 3px;
    top: -7px;
    z-index: 99;
    height: 50px;
    svg {
      font-size: 20px;
      color: #999999;
    }
  }
}
.profile-icon-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  h5 {
    font-family: "Lato";
    font-size: 18px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0em;
    text-transform: uppercase;
    color: #212529;
    margin: 0 0.5rem;
  }
}
.back-button-wrapper {
  margin: 6px 0 0;
}
.profile-wrapper {
  display: flex;
  align-items: center;
}
.profile_revert_btn {
  margin: 0 0 16px;
}