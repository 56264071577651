.board-header {
    padding: 1.5rem 0 1.5rem 0rem;

    @media (max-width: 600px) {
        padding: 1rem;
    }
}

.heading-content {
    border-left: 6px solid #2773B1;
    font-family: "Raleway" !important;
   
    .head_pad{
        margin-left: 20px !important;
    }

    h5 {
        padding-top: 5px;
        margin-bottom: 1px;
        font-family: 'Raleway';
        font-size: 22px;
        font-weight: 500;
        line-height: 26px;
        color: #646569;
    }

    .sub-title {
        font-family: 'Raleway';
        font-size: 18px;
        font-weight: 300;
        line-height: 38px;
        color: #646569;
    }
}