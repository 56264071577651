.timeoff_container {
  .timeoff_modal_select {
    padding: 13px 16px 12px 16px;
  }
  .year_dropdown {
    align-items: flex-end;
    button {
      width: 100%;
      padding: 13px 16px 12px 16px;
      border: 1px solid #e8e8e8;
      border-radius: 5px;
      text-align: left;
      &::after {
        position: absolute;
        right: 10px;
        top: 23px;
      }
    }
    .clr_white {
      color: white;
      text-align: center;
      font-weight: bold;
    }
    .dropdown-menu {
      width: inherit;
    }
  }

  table {
    tr {
      th {
        padding: 12px;
        @media (max-width: 515px) {
          padding-left: 25px;
          padding-right: 25px;
          white-space: nowrap;
        }
      }
      td {
        vertical-align: middle;
        padding: 12px;

        img {
          width: 0.85rem;
        }
        @media (max-width: 515px) {
          padding-left: 25px;
          padding-right: 25px;
          white-space: nowrap;
        }
      }
    }
  }
}
.icon-edit {
  cursor: pointer;
}
.primary-color {
  color: #2773b1;
}
.btn-back,
.btn-readmore {
  cursor: pointer;
}
.btn-back {
  width: 6%;
  display: flex;
}
.dropdown_button {
  margin-top: 30px;
}
.user_comments {
  word-wrap: break-word;
}
.border-right-wrapper {
  border-right: 0.5px solid #d9d9d9 !important;
}
.balance-info-text {
  position: relative;
  // justify-content: space-between;
  align-items: center;
  img {
    width: 18px;
    padding: 2px 0 0 2px;
    position: absolute;
  }
}
// .rc-tooltip {
//   width: 18%;
// }
.timeoff-tooltip {
  .rc-tooltip-content {
    width: 100%;
  }
  .rc-tooltip-inner {
    background: #212529 !important;
    border-radius: 8px !important;
    color: #fff;
  }
  .rc-tooltip-placement-right .rc-tooltip-arrow {
    border-right-color: #212529 !important;
  }
}
.paragraph-tooltip-wrapper {
  margin: 0;
}
@media screen and (max-width: 1024px) {
  .table_mobile_scroll {
    max-width: 1000px;
    overflow-x: scroll;
  }
}
@media screen and (max-width: 600px) {
  // .rc-tooltip {
  //   width: 40%;
  // }
}

.tab-container {
  margin: 0 0 1rem;
  ul {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    width: -webkit-fill-available;
    margin: 0 auto;
    border-bottom: 2px solid #edf1f2;
  }
}

.tab-container ul li.tab-item {
  padding: 10px 20px;
  margin: 0;
  font-family: Lato;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.2px;
  text-align: left;
  color: #ababab;
  cursor: pointer;
  position: relative;
}

.tab-container ul li.tab-item.active {
  color: #2773b1;
  font-weight: 700;
}

.tab-container ul li.tab-item.active::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 2px;
  background: #2773b1;
}
@media screen and (max-width: 1100px) {
  .tab-container {
    ul {
      width: auto;
      flex-wrap: nowrap;
      justify-content: space-between;
    }
  }
}

@media screen and (max-width: 600px) {
  .tab-container ul li.tab-item {
    font-size: 14px;
  }
}
.bg-cus-wrapper {
  background-color: #fff9e4 !important;
}

.tab-container {
  margin: 0 0 1rem;
  ul {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    width: -webkit-fill-available;
    margin: 0 auto;
    border-bottom: 2px solid #edf1f2;
  }
}

.tab-container ul li.tab-item {
  padding: 10px 20px;
  margin: 0;
  font-family: Lato;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.2px;
  text-align: left;
  color: #ababab;
  cursor: pointer;
  position: relative;
}

.tab-container ul li.tab-item.active {
  color: #2773b1;
  font-weight: 700;
}

.tab-container ul li.tab-item.active::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 2px;
  background: #2773b1;
}
@media screen and (max-width: 1100px) {
  .tab-container {
    ul {
      width: auto;
      flex-wrap: nowrap;
      justify-content: space-between;
    }
  }
}

@media screen and (max-width: 600px) {
  .tab-container ul li.tab-item {
    font-size: 14px;
  }
}
.bg-cus-wrapper {
  background-color: #fff9e4 !important;
}

.tab-container {
  margin: 0 0 1rem;
  ul {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    width: -webkit-fill-available;
    margin: 0 auto;
    border-bottom: 2px solid #edf1f2;
  }
}

.tab-container ul li.tab-item {
  padding: 10px 20px;
  margin: 0;
  font-family: Lato;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.2px;
  text-align: left;
  color: #ababab;
  cursor: pointer;
  position: relative;
}

.tab-container ul li.tab-item.active {
  color: #2773b1;
  font-weight: 700;
}

.tab-container ul li.tab-item.active::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 2px;
  background: #2773b1;
}
@media screen and (max-width: 1100px) {
  .tab-container {
    ul {
      width: auto;
      flex-wrap: nowrap;
      justify-content: space-between;
    }
  }
}

@media screen and (max-width: 600px) {
  .tab-container ul li.tab-item {
    font-size: 14px;
  }
}
.bg-cus-wrapper {
  background-color: #fff9e4 !important;
}