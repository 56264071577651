.exiting-payroll {
    background: #ffffff;
  
    .header {
      .card {
        border: none;
      }
  
      .card-header {
        font-family: "Lato";
        font-size: 24px;
        font-weight: 700;
        line-height: 29px;
        letter-spacing: 0em;
        text-align: left;
        color: #515253;
        background-color: #fff;
        border: none;
        border-bottom: 1px solid #e8e8e8;
        border-radius: 0;
        padding: 15px 15px 15px 15px;
      }
  
      .card-text {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: "Lato";
        font-size: 16px;
        font-weight: 500;
        line-height: 26px;
        letter-spacing: 0em;
        text-align: left;
  
        .heading {
          span {
            color: #2773b1;
          }
        }
      }
    }
  }
  .back_icon{
    margin-right: 10px ;
  }
  .back_btn{
    border: none;
    background: transparent;
  }