.two-factor-title {
  margin: 0 1.5rem;
}
.main-card-wrapper {
  background-color: #fff;
  h5 {
    font-family: "Lato";
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    color: #333333;
    margin: 0;
    padding: 18px 0;
    border-bottom: 1px solid #e8e8e8;
  }
  p {
    font-family: "Lato";
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #7a7a7a;
    padding: 16px 14px;
    width: 75%;
  }
}
.onboarding-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.two-factor-toggle-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 14px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 26px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #78788029;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: 4px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border: 0.5px solid #0000000a;
  box-shadow: 0px 1px 2px 0px #00000033;
  box-shadow: 0px 0.10000000149011612px 0.30000001192092896px 0px #0000001a;
}

input:checked + .slider {
  background-color: #5d9cec;
}

input:focus + .slider {
  box-shadow: 0 0 1px #5d9cec;
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(31px);
}
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.back-btn-wrapper {
  margin: 0 0 0 8px;
}
.two-factor-modal {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}
.two-factor-modal-footer {
  justify-content: center;
  border-top: 1px solid #f3f3f3;
}
.two-factor-modal-body {
  text-align: center;
  width: 85%;
  margin: auto;
  padding: 1rem 1rem 1.5rem;
}
.modal-btn-wrapper {
  width: 135px;
  height: 37px;
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 6px;
}
.confirm-btn {
  color: #fff;
  &:hover {
    color: #fff;
  }
}
.cancel-btn {
  color: #515253 !important;
  border: 1px solid #d9d9d9 !important;
}
@media screen and (max-width: 768px) {
  .onboarding-wrapper {
    // flex-direction: column;
    // align-items: start;
    margin: 0 0 1rem;
    .board-header {
      padding: 1rem 0;
    }
  }
  .two-factor-title {
    margin: 0;
  }
  .main-card-wrapper {
    p {
      width: 100%;
    }
  }
}
@media screen and (max-width: 390px) {
  input:checked + .slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(28px);
  }
}
@media screen and (max-width: 360px) {
  input:checked + .slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(24px);
  }
}
@media screen and (max-width: 340px) {
  input:checked + .slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(18px);
  }
}
