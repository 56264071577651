.jobs_container {
  .form-check-input:checked[type=radio]{
  background-image: url("../../../assets/images/radio_checked.svg") !important;
  background-color: white;
  border: 2px solid #0d6efd;
  }
  .was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked{
    background-image: url("../../../assets/images/radio_checked_green.svg") !important;
  }
  .table_mobile_scroll {
    @media (max-width: 515px) {
      overflow-x: scroll;
    }
  }

  div#react-select-4-placeholder,div#react-select-3-placeholder {
    font-size: 14px;
}
//   .custom-select div:first-child {
//     padding-bottom: 3.5px;
// }
}

.view-more-job {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #2773B1;
  border: none;
}
.form-check-label{
  cursor: pointer;
}
.tax-payer-check{
  .form-check-input:checked[type=radio]:disabled {
    background-color: #0d6efd !important;
    border-color: #0d6efd;
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%272%27 fill=%27%23fff%27/%3e%3c/svg%3e") !important;
  }
}
.no-data{
  font-size: 0.9rem !important;
  text-align: center;
  padding: 10px;
  font-weight: bold;
}

