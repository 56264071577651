.main-container-wrapper {
  margin: 6rem 0;
}
.employee-auth-card-wrapper {
  background-color: #fff;
  padding: 54px;
  border-radius: 20px;
  h5 {
    font-family: "Lato";
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    color: #252525;
    text-align: center;
  }
  p {
    font-family: "Lato";
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #252525;
  }
}
.back-to-login {
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    color: #2773b1 !important;
    font-family: "Lato";
    font-size: 16px;
    font-weight: 500 !important;
    line-height: 19.2px !important;
    margin: 0;
    cursor: pointer;
  }
}
.mobile-verification-wrapper {
  background-color: #fff;
  border-radius: 20px;
  h5 {
    font-family: "Lato";
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    color: #252525;
    text-align: center;
  }
  p {
    font-family: "Lato";
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #252525;
  }
}
.validation-text {
  font-family: "Lato";
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  color: #f04b4b;
  padding: 0 1.5rem;
}
.mobile-info-wrapper {
  border-bottom: 1px solid #d9d9d9;
  h5 {
    font-family: "Lato";
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    color: #252525;
    text-align: left;
  }
  p {
    font-family: "Lato";
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #252525;
    text-align: left;
  }
}
.phoneInputContainer {
  border: 1px solid #adadad;
  border-radius: 8px;
  padding: 8px;
  margin: 1rem 0;
  .PhoneInputCountry {
    border-right: 1px solid #6c757d;
    padding: 0 0.5rem;
  }
  input {
    border: none;
  }
}
.btn-primary:disabled {
  color: grey;
  background: #aaaaaa;
  border: #aaaaaa;
}
.otp-width-wrapper {
  width: 90%;
}
.success-card-wrapper {
  width: 75%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 0 0;
  h5 {
    font-family: "Lato";
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    text-align: center;
    color: #252525;
  }
  p {
    font-family: "Lato";
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #252525;
  }
}
.otp-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 1.5rem;
  input {
    border: 1px solid #adadad;
    border-radius: 8px;
    width: 50px !important;
    height: 50px !important;
  }
  p {
    padding: 0.5rem 0;
    margin: 0;
    color: #e81219;
    font-family: "Inter";
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
  }
}
.otp-wrapper-error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 1.5rem;
  input {
    border: 1px solid #f04b4b;
    border-radius: 8px;
    width: 50px !important;
    height: 50px !important;
  }
  p {
    padding: 0.5rem 0;
    margin: 0;
    color: #e81219;
    font-family: "Inter";
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    text-align: center;
    width: 46%;
  }
}
.resend-otp-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.resend-disabled {
  color: #aaaaaa !important;
  font-family: "Lato";
  font-size: 16px;
  font-weight: 500 !important;
  line-height: 19.2px !important;
  margin: 0;
  cursor: not-allowed;
}
.resend-enabled {
  color: #2773b1 !important;
  font-family: "Lato";
  font-size: 16px;
  font-weight: 500 !important;
  line-height: 19.2px !important;
  margin: 0;
  cursor: pointer;
}
.modal-email-wrapper {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.modal-email-body-wrapper {
  text-align: center;
  width: 80%;
  margin: auto;
}
.modal-email-footer-wrapper {
  justify-content: center;
}
.modal-email-footer-button {
  color: #fff;
  &:hover {
    color: #fff;
  }
}
.phonenumberinput {
  display: flex;
  align-items: center;
  border: 1px solid #adadad;
  border-radius: 8px;
  padding: 8px;
  width: 90%;
  margin: auto;
  span {
    color: #6c757d;
    border-right: 1px solid #6c757d;
    padding: 0 0.5rem 0 0;
  }
  input {
    border: none;
    padding: 0 0 0 0.5rem;
    font-family: "Lato";
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    color: #6c757d;
  }
}
.phonenumbererror {
  display: flex;
  align-items: center;
  border: 1px solid #f04b4b;
  border-radius: 8px;
  padding: 8px;
  width: 90%;
  margin: auto;
  span {
    color: #6c757d;
    border-right: 1px solid #6c757d;
    padding: 0 0.5rem 0 0;
  }
  input {
    border: none;
    padding: 0 0 0 0.5rem;
    font-family: "Lato";
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    color: #6c757d;
  }
}
.loader-wrapper {
  height: 100vh;
}
.width-cus {
  width: 15%;
}
@media screen and (max-width: 768px) {
  .main-container-wrapper {
    margin: 3rem 0;
  }
  .container-padding {
    padding: 0;
  }
  .otp-wrapper-error {
    padding: 1rem 0rem;
    p {
      text-align: center;
      width: 100%;
    }
  }
  .otp-wrapper {
    padding: 1rem 0rem;
  }
  .otp-width-wrapper {
    width: 100%;
  }
  .otp-input-cus {
    align-items: center;
    justify-content: space-evenly;
    width: 83%;
    input {
      margin-right: 0 !important;
    }
  }
}
