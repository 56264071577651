.btn-wrapper {
  // width: 50%;
  button {
    text-transform: uppercase;
    box-shadow: 0px 1px 3px 0px #0000001a;
    box-shadow: 0px 1px 2px 0px #00000033;
  }
}
.time-off-btn {
  color: #2773b1;
  font-family: "Lato";
  font-size: 11px;
  font-weight: 400;
  line-height: 14px;
  text-align: center;
  padding: 0.65rem 1.75rem;
  border-radius: 3px;
  border: 1px solid #2773b1;
  background: #fff;
  margin: 0 0 0 1rem;
}
.add-btn {
  color: #fff;
  font-family: "Lato";
  font-size: 11px;
  font-weight: 400;
  line-height: 14px;
  text-align: center;
  background: #2773b1;
  padding: 0.65rem 1.75rem;
  border-radius: 3px;
  border: 1px solid #2773b1;
  &:hover {
    color: #fff;
  }
}
.list-text-wrapper {
  display: flex;
  align-items: center;
  border-bottom: 2px solid #2773b1;
  padding: 0 0 0.5rem;
  p {
    margin: 0 0.5rem;
    font-family: "Lato";
    font-size: 14px;
    font-weight: 700;
    line-height: 16.8px;
    color: #2773b1;
  }
}
.calender-text-wrapper {
  display: flex;
  align-items: center;
  margin: 0 0rem 0 0.5rem;
  padding: 0 0rem 0.5rem 0.5rem;
  border-left: 1px solid #d9d9d9;
  p {
    margin: 0 0.5rem;
    font-family: "Lato";
    font-size: 14px;
    font-weight: 700;
    line-height: 16.8px;
    color: #646569;
  }
}
.approve_deny_btn {
  button {
    background: #edf1f2;
    box-shadow: 0px 1px 3px 0px #0000001a;
    box-shadow: 0px 1px 2px 0px #00000033;
    border-radius: 3px;
    color: #757575;
    margin: 0 0.5rem 0 0;
    border: none;
    font-family: "Lato";
    font-size: 11px;
    font-weight: 500;
    line-height: 14px;
    text-align: center;
    text-transform: uppercase;
    padding: 0.35rem 0.5rem;
  }
}
.btn-pto-wrapper {
  display: flex;
  // border: 1px solid #d9d9d9;
}
.pto-log-current-btn {
  background-color: #fff;
  border: 1px solid #d9d9d9;
  font-family: "Lato";
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  color: #000;
  width: 125px;
  height: 43px;
  border-left: 0.5x solid #d9d9d9;
  border-top: 0.5px solid #d9d9d9;
  border-bottom: 0.5px solid #d9d9d9;
  border-right: 0;
  &:active,
  &.active {
    background-color: #ebf2f8;
  }
}
.pto-log-upcoming-btn {
  background-color: #fff;
  border: 1px solid #d9d9d9;
  font-family: "Lato";
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  color: #000;
  width: 125px;
  height: 43px;
  border: none;
  border-left: 0;
  border-top: 0.5px solid #d9d9d9;
  border-bottom: 0.5px solid #d9d9d9;
  // border-right: 1px solid #d9d9d9;
  &:active,
  &.active {
    background-color: #ebf2f8;
  }
}
.pto-log-denied-btn {
  background-color: #fff;
  border: 1px solid #d9d9d9;
  font-family: "Lato";
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  color: #000;
  width: 125px;
  height: 43px;
  border: none;
  border-left: 0;
  border-top: 0.5px solid #d9d9d9;
  border-bottom: 0.5px solid #d9d9d9;
  border-right: 0.5px solid #d9d9d9;
  &:active,
  &.active {
    background-color: #ebf2f8;
  }
}
.approve-all-btn-wrapper {
  // background: #fff;
  padding: 0rem 0rem 0;
  margin: 0;
  justify-content: end;
}
.approve-timeoff-btn {
  background: #c5c5c5;
  border: 1px solid #c5c5c5;
  font-family: "Lato";
  font-size: 11px;
  font-weight: 400;
  line-height: 14px;
  text-align: center;
  padding: 0.75rem 3rem;
  border-radius: 3px;
  color: #fff;
  margin: 0 0.5rem 0 0;
}
.deny-timeoff-btn {
  background: #fff;
  border: 1px solid #c5c5c5;
  font-family: "Lato";
  font-size: 11px;
  font-weight: 400;
  line-height: 14px;
  text-align: center;
  padding: 0.75rem 3rem;
  border-radius: 3px;
  color: #c5c5c5;
  margin: 0 0rem 0 0;
}

.pto-filter-dropdown {
  p {
    font-family: "Lato";
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.01em;
    margin: 0 0 6px;
    color: #515253;
  }
  .css-b62m3t-container {
    min-width: 192px;
    max-width: 240px;
  }
  .css-tlfecz-indicatorContainer {
    background: #fff;
  }
  .css-1okebmr-indicatorSeparator {
    margin: 0;
    width: 0;
  }
  .css-tj5bde-Svg {
    color: #1c1b1f;
  }
}
.pto-table {
  background: #fff;
  width: 100%;
  thead,
  tbody,
  tfoot,
  tr,
  td,
  th {
    padding: 1.1rem 1rem;
  }
  tr {
    border-bottom: 1px solid #d9d9d9;
  }
  th {
    font-family: "Lato";
    font-style: normal;
    font-weight: 500;
    font-size: 0.9rem;
    line-height: 19px;
    color: #000;
    background: #ebf2f8;
    border-top: 1px solid #d9d9d9;
  }
  td {
    font-family: "Lato";
    font-size: 14px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: left;
    color: #6e6e6e;
  }
  td {
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
  }

  td:hover {
    overflow: visible;
    white-space: normal;
  }
}
.active-pagination-btn {
  font-weight: 700;
  color: #fff;
  background: #2773b1;
  padding: 3px 12px;
  border: 1px solid #d0d0d0;
  border-radius: 4px;
  margin-right: 3px;
}
.non-active-pagination-btn {
  background-color: transparent;
  border-color: transparent;
  padding-top: 0;
  padding-bottom: 0;
  color: #333333;
  border: 1px solid #d0d0d0;
  border-radius: 4px;
  margin-right: 3px;
  padding: 3px 12px;
}
.approve-btn-active {
  font-family: "Lato";
  font-size: 11px;
  font-weight: 400;
  line-height: 14px;
  text-align: center;
  padding: 0.75rem 3rem;
  border-radius: 3px;
  color: #fff;
  margin: 0 0.5rem 0 0;
  background: #2773b1;
  border-radius: 3px;
  border: 1px solid #2773b1;
}
.deny-btn-active {
  font-family: "Lato";
  font-size: 11px;
  font-weight: 400;
  line-height: 14px;
  text-align: center;
  padding: 0.75rem 3rem;
  border-radius: 3px;
  color: #2773b1;
  margin: 0 0rem 0 0;
  background: #fff;
  border-radius: 3px;
  border: 1px solid #2773b1;
}
.time-off-title-card {
  padding: 1rem 1rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.back-button-wrapper {
  padding: 0 1rem 1rem;
  display: flex;
  justify-content: space-between;
  .setting-backbutton {
    height: 22px;
    h5 {
      padding: 0 15px 0rem;
    }
  }
}
.list-main-wrapper {
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 0.25rem 0 0rem;
}
.pto-dropdown-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: start;
  padding: 0 1rem;
}
.pto-table-container {
  padding: 0 0 1rem;
  margin: 0 1rem;
}
.no-data-text {
  display: flex;
  background: #fff;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  grid-gap: 1rem;
  padding: 5rem 0;
  h5 {
    font-family: "Lato";
    font-size: 16px;
    font-weight: 500;
    line-height: 19.2px;
    color: #aaaaaa;
  }
}
.disabled-button {
  // pointer-events: none;
  cursor: not-allowed !important;
}

.timeoff-calender-tooltip {
  width: 15%;
  .rc-tooltip-content {
    width: 100%;
  }
  .rc-tooltip-inner {
    background: #212529 !important;
    border-radius: 8px !important;
    color: #fff;
  }
  .rc-tooltip-placement-right .rc-tooltip-arrow {
    border-right-color: #212529 !important;
  }
}
.time-off-cal-search-approve-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.75rem 0rem;
  // background: #fff;
  margin: 0 1rem 0.75rem;
}
.time-off-cal-input-search-wrapper {
  width: 361px;
  padding: 0;
  background: #fff;
  input {
    border: none;
  }
  .input-group {
    border: 1px solid #e4e4e7;
    border-radius: 4px;
    padding: 8px;
    align-items: center;
  }
  .form-control {
    padding: 0rem 0.75rem;
  }
}
.date-sort-wrapper {
  cursor: pointer;
  img {
    width: 16px;
    height: 12px;
  }
}
// @media (max-width: 1024px) {
//   .time-off-btn {
//     margin: 0;
//   }
//   .btn-wrapper {
//     // flex-direction: column;
//     // grid-gap: 1rem;
//     width: 100%;
//     padding: 0 0 1rem;
//   }
//   .approve_deny_btn {
//     display: flex;
//     justify-content: space-between;
//   }
//   .time-off-title-card {
//     flex-direction: column;
//     padding: 1rem 0 0;
//     align-items: start;
//     grid-gap: 1rem;
//     .board-header {
//       padding: 0;
//     }
//   }
//   .back-button-wrapper {
//     padding: 0;
//   }
//   .list-main-wrapper {
//     justify-content: start;
//   }
//   .pto-dropdown-wrapper {
//     flex-direction: column-reverse;
//     grid-gap: 1rem;
//     padding: 0;
//   }
//   .approve-all-btn-wrapper {
//     margin: 0;
//   }
// }
@media (max-width: 1024px) {
  .pto-table-scroll {
    overflow-x: scroll;
  }
}
@media (max-width: 1024px) {
  .pto-dropdown-wrapper {
    flex-direction: column-reverse;
    grid-gap: 1rem;
    padding: 0 1rem;
  }
  .pto-filter-dropdown {
    margin: 1rem 0 0;
  }
  .time-off-cal-search-approve-wrapper {
    padding: 1.75rem 0rem 0;
  }
  .time-off-cal-input-search-wrapper {
    width: 300px;
  }
}
@media (max-width: 700px) {
  .time-off-btn {
    margin: 0;
  }
  .btn-wrapper {
    width: 100%;
    padding: 0 0 1rem;
  }
  .approve_deny_btn {
    display: flex;
    justify-content: space-between;
  }
  .time-off-title-card {
    flex-direction: column;
    padding: 1rem 0 0;
    align-items: start;
    grid-gap: 1rem;
    .board-header {
      padding: 0;
    }
  }
  .back-button-wrapper {
    align-items: center;
    padding: 0rem 0 1rem;
  }
  .list-main-wrapper {
    justify-content: start;
  }
  .pto-dropdown-wrapper {
    padding: 0;
  }
  .approve-all-btn-wrapper {
    margin: 1rem 0;
    justify-content: end;
    padding: 0;
    width: 100%;
    grid-gap: 1rem;
  }
  .pto-table-container {
    margin: 0;
  }
  .btn-pto-wrapper {
    width: 100%;
    justify-content: space-between;
  }
  .pto-log-current-btn {
    width: -webkit-fill-available;
  }
  .pto-log-denied-btn {
    width: -webkit-fill-available;
  }
  .pto-log-upcoming-btn {
    width: -webkit-fill-available;
  }
  .timeoff-calender-tooltip {
    width: 35%;
  }
  .time-off-cal-search-approve-wrapper {
    margin: 0;
    flex-direction: column;
  }
  .approve-timeoff-btn {
    margin: 0;
  }
  .approve-btn-active {
    margin: 0;
  }
  .approve-all-btn-wrapper {
    padding: 0.5rem 0rem 0;
  }
  .time-off-cal-input-search-wrapper {
    width: 100%;
    border: none;
    padding: 0;
  }
  .no-data-text {
    padding: 5rem 1rem;
  }
}
