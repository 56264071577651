.notes_container{
    .add_notes_btn{
        @media (min-width:767px) {
            justify-content: end;
        }
    }
    .row{
        @media (min-width:915px) {
          --bs-gutter-x:unset !important
        }
      }
}


.read-more-note{
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color:#2773B1;
    cursor: pointer;
}
.edit-notes{
    width: 18px;
    height: 18px;
}