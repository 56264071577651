.employee-title-wrapper {
  border-bottom: 1px solid #e8e8e8;
  h5 {
    text-align: center;
    width: 40%;
    margin: auto;
    font-family: "Lato";
    font-size: 32px !important;
    font-weight: 700;
    line-height: 38.4px !important;
    color: #252525 !important;
  }
}
.onboarding-form-wrapper {
  margin: 2rem 0 0;
}
.create-btn-wrapper {
  border-top: 1px solid #e8e8e8;
  padding: 1.25rem 0 0;
}
.create-btn {
  span {
    text-transform: uppercase;
    font-family: "Lato";
    font-size: 11px;
    font-weight: 400;
    line-height: 14px;
    text-align: center;
  }
}
.term-condition-wrapper {
  // width: 80%;
  // margin: auto;
  padding: 0 1.5rem;
}
.agree-subtitle-wrapper {
  .form-check {
    .form-check-input {
      margin-right: 0;
    }
  }
}
.employee-onboarding-setup-wrapper {
  .create-board {
    padding: 1.5rem 0;
  }
}
@media (max-width: 768px) {
  .employee-title-wrapper {
    h5 {
      width: 95%;
      font-size: 24px !important;
    }
  }
}
