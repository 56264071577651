.main-board {
  padding: 20px;
}

.company-settings {
  background: var(--white);

  .accordion {
    padding: 20px;
  }

  .accordion-button:not(.collapsed)::after {
    background-image: url("../../assets/images/expand_mini.svg") !important;
  }

  .accordion-button::after {
    background-image: url("../../assets/images/expand_mini.svg") !important;
  }

  .accordion-button:focus {
    box-shadow: none;
  }

  .accordion-item {
    margin-bottom: 15px;
    border: none;

    .accordion-header {
      .accordion-button {
        background: #e4e8eb;
        border-radius: 8px;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: var(--black);
      }
    }
  }
}
.accordion-title-wrapper {
  display: flex;
  width: 90%;
  justify-content: space-between;
  /* padding: 0 2rem 0 0; */
  align-items: center;
  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: var(--black);
    margin: 0;
  }
  h5 {
    margin: 0;
  }
}
.signatory-sub-title {
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 22px !important;
  color: var(--black) !important;
}

.company-settings-form {
  .form-select:disabled {
    background: #e8e8e8 !important;
    border: 1px solid #e8e8e8 !important;
  }
}

.add-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: var(--black);
}

.delete-text {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: var(--blue);
  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;
    margin-top: -5px;
  }
}

.company-add-address {
  .form-check-input:checked[type="checkbox"]:disabled {
    background-color: #0d6efd !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%23fff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27m6 10 3 3 6-6%27/%3e%3c/svg%3e") !important;
  }

  .form-check {
    @media (max-width: 450px) {
      display: flex !important;
    }
  }

  .form-check-input {
    @media (min-width: 350px) and (max-width: 400px) {
      width: 17px !important;
      height: 16px !important;
    }

    @media (min-width: 300px) and (max-width: 349px) {
      width: 21px !important;
      height: 17px !important;
    }

    @media (min-width: 200px) and (max-width: 299px) {
      width: 29px !important;
      height: 17px !important;
    }
  }
}

.pay-frequency {
  .accordion-item .accordion-header .accordion-button {
    background: #ebf2f8 !important;
  }
}

.add-addres-settings {
  .custom-select > div {
    padding: 6px;
  }

  #react-select-11-listbox,
  #react-select-6-listbox,
  #react-select-5-listbox {
    z-index: 9 !important;
  }
}

.payroll-company {
  .form-select {
    background: #e8e8e8 !important;
    border: 1px solid #e8e8e8 !important;
    border-radius: 5px;
  }
}

.tooltip-content {
  @media screen and (max-width: 400px) {
    left: 20% !important;
  }

  @media (min-width: 400px) and (max-width: 1000px) {
    left: 30% !important;
  }
}

.card_container {
  .cards {
    width: calc((100%) / 3);
  }

  @media screen and (max-width: 1030px) {
    .cards {
      width: calc((100%) / 2);
    }
  }

  @media screen and (max-width: 812px) {
    .cards {
      width: 100%;
    }
  }
}

@media screen and (max-width: 450px) {
  .mob_tooltip {
    width: 180px !important;
  }
}
.bank-verify {
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: var(--blue);
}

.new_company {
  .payroll-sub-title {
    font-size: 14px !important;
  }
  .custom-form .form-label {
    font-size: 14px !important;
  }
}
.micro-span {
  font-size: 14px !important;
  color: #959697;
}
.btn-payFrequency {
  border-radius: 3px;
  border: 1px solid #2773b1;
  padding: 11px 14px;
  color: #2773b1;
  font-size: 11px !important;
  font-weight: 400;
}
.accordion-header-wrapper {
  width: 100%;
  align-items: center;
}
.onboarding-errors {
  background-color: #f04b4b;
  font-family: "Lato";
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  border-radius: 6px;
  width: 87px;
  height: 31px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.active-status-errors {
  background-color: #f04b4b;
  font-family: "Lato";
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  border-radius: 6px;
  width: 87px;
  height: 31px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.active-status-success {
  background-color: green;
  font-family: "Lato";
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  border-radius: 6px;
  width: 87px;
  height: 31px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
