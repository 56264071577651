.sign-doc-view {
    background-color: #FFF;
    padding: 20px;

    .iframe-loading {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 250px;
        font-size: 24px;
        font-weight: bold;
    }

    iframe {
        min-height: 400px;
    }

    .sign-details-name {
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        color: #6E6E6E;

        .sign-download-btn {
            font-weight: 400;
            font-size: 16px;
            line-height: 18px;
            text-decoration-line: underline;
            color: #6E6E6E;
            background: none;
            border: none;
        }
    }

    .sign-frame {
        display: flex;
        justify-content: center;
        align-items: center;
        // flex-direction: column;
    }

    .sign-doc-btn {
        .btn {
            width: 112px;
            height: 48px;
        }

    }

    .sign-text {
        -webkit-writing-mode: horizontal-tb !important;
        writing-mode: horizontal-tb !important;
        font-family: "Moonstone-Regular, cursive" !important;
    }

}
