.employee-status {
    .server-side-table {
        padding: 15px;

        .search {
            display: none;
        }

        .table .thead-dark th {
            background: #EBF2F8;
            font-size: 16px;
            font-weight: 500;
            color: #000;
        }

        .react-bootstrap-table-pagination {
            background: #FFF;
            padding: 30px 10px 20px 10px;
        }
    }

    .emp-action-status {
        .btn {
            font-size: 14px;
            font-weight: 500;
            color: #2773B1;
            background: #FFF;
            border: none;
            text-decoration: underline;
            cursor: pointer;
        }
    }
}