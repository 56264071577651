.sign-doc-view {
  background-color: #fff;
  padding: 0px !important;

  .iframe-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 250px;
    font-size: 24px;
    font-weight: bold;
  }

  iframe {
    min-height: 400px;
  }

  .sign-details-name {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #6e6e6e;

    .sign-download-btn {
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      text-decoration-line: underline;
      color: #6e6e6e;
      background: none;
      border: none;
    }
  }

  .sign-frame {
    display: flex;
    justify-content: center;
    align-items: center;
    // flex-direction: column;
  }

  .sign-doc-btn {
    .btn {
      width: 112px;
      height: 48px;
    }
  }

  .sign-text {
    -webkit-writing-mode: horizontal-tb !important;
    writing-mode: horizontal-tb !important;
    font-family: "Moonstone-Regular, cursive" !important;
  }
}
@media (min-width: 576px) {
  .modal-sign-main-wrapper {
    .modal-dialog {
      max-width: 60%;
    }
  }
}
