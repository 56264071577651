.assesment_container {
  .select_year_font {
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    color: #000000;
  }

  .row{
    @media (min-width:915px) {
      --bs-gutter-x:unset !important
    }
  }

  .dropdown-toggle {
    width: 350px;
    border: 1px solid;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 13px 16px 12px 24px;
    width: 216px;
    height: 49px;
    background: #ffffff;
    border: 1px solid #e8e8e8;
    border-radius: 5px;
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    color: #000000;
  }

  .assesment_table_container {
    .assesment_header {
      height: 69px;
      background: #e8e8e8;
      border: 1px solid #e8e8e8;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      color: #000000;
      font-family: "Lato";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 18px;
    }

    .white_bg {
      background-color: transparent !important;
    }

    .radio_bdr_clr {
      input {
        border: 2px solid #1c1b1f;
      }
    }

    .custom_assesment_row {
      padding: 15px 5px;
      background: #ffffff;
      border: 1px solid #e8e8e8;
      border-top: 0px;

      @media (min-width: 767px) {
        border-right: 1px solid #e8e8e8;
        
        // min-height: 171px;
      }
    }

    div {
      .row {
        border-bottom: 1px solid #e8e8e8;
      }
    }
  }

  .form_radio_btn_container {
    padding: 0px;

    @media (min-width: 365px) {
      padding: 37px;
    }
  }

  .form-check-input:checked[type=radio]:disabled {
    background-color: #0d6efd !important;
    border-color: #0d6efd;
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%272%27 fill=%27%23fff%27/%3e%3c/svg%3e") !important;

  }
}