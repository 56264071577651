.time-card-container {
}
.payrecord-container {
  background: #fff;
}
.title-wrapper {
  background: #f9f9f9;
  display: flex;
  padding: 16px;
  align-items: center;
  h5 {
    font-family: "Raleway";
    font-style: normal;
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0em;
    text-transform: uppercase;
    margin: 0;
  }
  img {
    padding: 0 1rem 0 0;
  }
}
.subtitle-wrapper {
  padding: 16px;
  h3 {
    font-family: "Lato";
    font-style: normal;
    font-size: 24px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0em;
    margin: 18px 0 32px;
  }
}
.request-cards-wrapper {
  border-radius: 4px;
  box-shadow: 0px 0px 6px 0px #0000001a;
  padding: 12px 24px;
  p {
    font-family: "Lato";
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    color: #515253;
  }
  h5 {
    font-family: "Lato";
    font-style: normal;
    font-size: 24px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0em;
  }
}
.total-cards {
  color: #2773b1;
}
.pending-cards {
  color: #ebb810;
}
.approved-cards {
  color: #6aa84f;
}
.gap-class {
  grid-gap: 25px;
}
.button-text-wrapper {
  font-family: "Lato";
  font-style: normal;
  font-size: 11px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
}
.whos-working-main-wrapper {
  margin: 46px 0;
}
.whos-working-title {
  justify-content: space-between;
  margin: 0 46px 24px;
  align-items: center;
  h5 {
    color: #6e6e6e;
    font-family: "Lato";
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0em;
    margin: 0;
    padding: 0 0 1rem;
  }
  h4 {
    color: #029f5b;
    font-family: "Lato";
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    margin: 0;
    padding: 0 0 1rem;
  }
}
.profile-name-wrapper {
  h5 {
    font-family: "Lato";
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #6e6e6e;
  }
}
.border-class {
  border-bottom: 1px solid #eeeeee;
}
.border-bottom-cus {
  border-bottom: 1px solid #515253;
}
.header-caps {
  text-transform: uppercase;
}
.time-keeping-wrapper {
  display: flex;
  margin: 0 0 30px;
}
.time-keeping-wrapper-responsive {
  display: none;
}
.profile-name-main-wrapper {
  margin: 0 24px;
}
.view-all-request-wrapper {
  display: flex;
  justify-content: end;
  border-bottom: 1px solid #eeeeee;
  margin: 5px 30px 0 16px;
  padding: 0 0 10px;
  p {
    color: #27a8df;
    font-family: "Lato";
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    margin: 0;
  }
}
.profile-wrapper {
  margin: 1rem;
  border-bottom: 1px solid #eeeeee;
}
.profile-sub-wrapper {
  display: flex;
  justify-content: space-between;
  h5 {
    font-family: "Lato";
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    color: #6e6e6e;
    margin: 0;
  }
}
.sub-text-wrapper {
  display: flex;
  justify-content: space-between;
  width: 85%;
  margin: 0.75rem 0 1rem;
  p {
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    color: #27a8df;
    margin: 0;
  }
  h3 {
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    color: #6e6e6e;
    margin: 0;
  }
  h5 {
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    color: #6e6e6e;
    margin: 0;
  }
}
.profile-btn-wrapper {
  button {
    font-family: "Lato";
    font-size: 11px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0em;
    color: #757575;
    background: #eae9e9;
    box-shadow: 0px 1px 3px 0px #0000001a;
    border: none;
    text-transform: uppercase;
    padding: 5px 8px;
  }
}
.profile-main-container {
  height: 300px;
  overflow-y: scroll;
}
.pagination {
  justify-content: end;
  margin: 0 16px 16px;
  --bs-pagination-border-radius: 0;
}
.page-item {
  --bs-pagination-active-bg: #2773b1;
}
.page-link {
  color: #515253;
}
.text-info-cus {
  font-family: "Lato";
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0em;
  margin: 6px 0;
  color: #27a8df;
}
.timoff-whos-wrapper {
  width: 85%;
}
@media screen and (max-width: 1024px) {
  .time-keeping-wrapper {
    display: none;
  }
  .time-keeping-wrapper-responsive {
    display: flex;
    flex-direction: column;
  }
  .title-wrapper {
    align-items: center;
    img {
      padding: 0 12px 0 0;
    }
    h5 {
      margin: 0;
    }
  }
  .subtitle-wrapper h3 {
    font-size: 18px;
    margin: 10px 0 18px;
  }
  .card-section-wrapper {
    flex-direction: column;
    align-items: center;
  }
  .card-sub-section-wrapper {
    flex-direction: column;
  }
  .button-text-wrapper {
    margin: 25px 0 0;
  }
}
@media screen and (max-width: 768px) {
  .time-card-container {
    .board-header {
      padding: 1rem 0;
    }
  }
}
