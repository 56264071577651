.title-common-wrapper {
  // margin: 1rem 0;
  h3 {
    font-family: "Lato";
    font-size: 16px;
    font-weight: 700;
    line-height: 28px;
    margin: 0;
    color: #252525;
  }
  h2 {
    font-family: "Lato";
    font-size: 18px;
    font-weight: 700;
    line-height: 21.6px;
    margin: 0;
    color: #252525;
  }
  p {
    font-family: "Lato";
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    color: #6e6e6e;
  }
}
