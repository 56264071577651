.setting-backbutton {
    display: flex;
    align-items: baseline;
    cursor: pointer;
    img {
      padding: 0;
    }
    h5 {
      font-family: "Lato";
      font-size: 18px;
      font-weight: 600;
      line-height: 22px;
      letter-spacing: 0em;
      color: #333333;
      margin: 0;
      padding: 0 15px 1rem;
    }
  }