.custom-accordion .accordion-header {
  font-weight: bold;
  color: #0056b3; /* Matches primary color in your design */
}

.custom-accordion {
  .accordion-body {
    padding: 20px;
  }
  .accordion-button:not(.collapsed) {
    background: #f5f5f5;
  }
  .accordion-button {
    background: #f5f5f5;
  }
  .accordion-item {
    border: none;
  }
}
.onboarding-accordion-wrapper {
  background: #fff;
  padding: 1rem 5rem;
}
.onboarding-title-wrapper {
  color: #2773b1;
  font-family: "Lato";
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
  margin: 1rem 0 2rem;
  text-transform: uppercase;
}
.onboarding-accordion-custom-header {
  width: 95%;
  span {
    font-family: "Lato";
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    color: #252525;
    text-transform: uppercase;
  }
}
.onboarding-accordion-edit-icon {
  width: 20px;
  height: 20px;
}
@media (max-width: 768px) {
  .onboarding-accordion-wrapper {
    padding: 1rem;
  }
  .onboarding-title-wrapper {
    font-size: 20px;
    line-height: 28px;
    margin: 1rem 0;
  }
  .onboarding-accordion-custom-header {
    width: 90%;
    span {
      font-size: 16px;
      line-height: 22px;
    }
  }
}
//   .onboarding-summary-wrapper{}
