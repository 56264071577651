.tasks_container {
  .tasks_btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10.273px 20.6089px 10.8342px 20.8094px;
    background: #edf1f2;
    border: 1.00225px solid #dddddd;
    width: 100%;
    font-family: "Lato";
    font-style: normal;
    font-weight: 700;
    font-size: 14.0314px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: #6e6e6e;

    &:hover {
      cursor: pointer;
    }
  }

  .active {
    background-color: white;
  }

  .pagination {
    display: flex;
    justify-content: end;

    button {
      padding: 10px;
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      font-family: "Lato";
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      margin-right: 5px;
    }

    .active_btn {
      background: #2773b1;
      border-radius: 4px;
      color: white;
    }

    .non_active_btn {
      background: #ffffff;
      border: 1px solid #d0d0d0;
      border-radius: 4px;
      color: #333333;
    }
  }
}

.delete-heading {
  font-size: 18px;
}

.btn-delete {
  cursor: pointer;

  @media (max-width:515px) {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
  }
}

.btn-delete1 {
  margin-right: 40px;
  cursor: pointer;
  border: 1px solid #2773b1;
  background: #FFF;
  padding: 9.5px 1.5rem;

  &:hover {
    background: #FFF;
    color: #2773b1;
  }
}

.user-error {
  color: red;
}

.document {
  cursor: pointer;
  color: #2773b1;
}

.tasks_save_cancel_container {
  display: flex;
  justify-content: end;

  @media (max-width:515px) {
    justify-content: center;

  }
}

.task_form {
  .form-check-input:checked[type=radio] {
    background-image: url("../../../assets/images/radio_checked.svg") !important;
    background-color: white;
    border: 2px solid #0d6efd;
  }
  .was-validated .form-check-input:checked[type=radio]:valid:checked , .form-check-input:checked[type=radio].is-valid:checked {
    background-color: white !important;
  }

  .was-validated .form-check-input:valid:checked,
  .form-check-input.is-valid:checked {
    // background-image: url("../../../assets/images/radio_checked_green.svg") !important;
    // background-color: none;
    background-color: #1170fd !important;
  }

  .was-validated .form-check-input:valid~.form-check-label {
    color: #000 !important;
  }
}