.emp-onboard-tabs {
  .nav-tabs .nav-link {
    width: 150px;
  }
}

.emp-sub-title {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: var(--black1);
}

.employee-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 123%;
  color: var(--gray4);
  max-width: 70%;
  margin-bottom: 15px;

  a {
    color: var(--blue);
    font-weight: 600;
  }
}

.employee-points {
  font-weight: 900;
  font-size: 14px;
  line-height: 22px;
  color: var(--black1);

  li {
    font-weight: 500;
  }

  .active li {
    color: var(--blue);
    font-weight: 600;
  }
}

.employee-fedral {
  font-weight: 400;
  font-size: 14px;
  line-height: 123%;
  color: var(--gray4);
  width: 70%;
}

.blue_bg_card {
  background: #ebf2f8;
}

.white_bg_card {
  background: #ffffff;
}

.onboard_task_title {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #1a202c;
}

.file_list {
  max-width: 581px;
  background: var(--white);
  margin: 5px 0px;
  padding: 10px 10px;

  // .file-card {
  //     margin-top: 15px;
  // }

  .file_name {
    color: #77767e;
    font-weight: 500;
    font-size: 13px;
    line-height: 12px;
  }

  .file_size {
    font-weight: 300;
    font-size: 12px;
    line-height: 12px;
    color: #77767e;
    margin-right: 10px;
  }

  .remove_file {
    line-height: normal;
    cursor: pointer;
    font-size: 12px;
    color: #77767e;

    svg {
      font-size: 20px;
    }
  }
}

.task_card {
  border: 1px solid #2773b1;
  border-radius: 8px;

  .task_detail {
    flex-basis: 70%;

    p {
      margin: 0px;
    }

    .task_title {
      font-weight: 400;
      font-size: 18px;
      color: #000000;
      margin-bottom: 8px;
    }

    .task_time {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #7a7a7a;
    }
  }

  .task_action {
    flex-basis: 30%;
  }
}

.emp-onboard-tabs {
  .nav-tabs .nav-link {
    width: 150px;
  }
}

.emp-sub-title {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: var(--black1);
}

.employee-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 123%;
  color: var(--gray4);
  max-width: 70%;
  margin-bottom: 15px;

  a {
    color: var(--blue);
    font-weight: 600;
  }
}

.employee-points {
  font-weight: 900;
  font-size: 14px;
  line-height: 22px;
  color: var(--black1);

  li {
    font-weight: 500;
  }

  .active li {
    color: var(--blue) !important;
    font-weight: 600 !important;
    padding-top: 7px;
  }
}

.employee-fedral {
  font-weight: 400;
  font-size: 14px;
  line-height: 123%;
  color: var(--gray4);
  width: 70%;
}

.drag-background {
  background: #f9f9f9;
  border-radius: 8px;
}

.employee-document-upload {
  min-width: 170px !important;
  height: 125px !important;
  max-width: 100% !important;
  margin: auto;
  background: var(--white);
  text-align: center;

  .bXgVCj {
    font-weight: 600;
    font-size: 16px !important;
    line-height: 120%;
    letter-spacing: -0.32px;
    color: #afafaf !important;

    @media (max-width: 300px) {
      font-size: 10px !important;
    }
  }

  .file-types {
    @media (max-width: 300px) {
      font-size: 10px !important;
    }
  }

  // .sc-hLBbgP.bXgVCj{
  //     display: none;
  // }
  svg {
    display: none;
  }

  .dmajdp {
    display: inline !important;
  }
}

.view_task_modal {
  .modal-header {
    box-shadow: none;
    padding: 20px 10px 0 20px;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: var(--black1);
  }

  .modal-body {
    padding: 0px 10px 20px 20px;
  }

  .btn-close {
    display: none;
  }
}

.onboard-task-title {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: var(--black1);
}

.onboard-sub-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #7a7a7a;
}

.onboard-body {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: var(--black1);

  a {
    color: var(--blue);
    font-weight: 600;
  }
}

#react-select-2-listbox,
#react-select-3-listbox,
#react-select-7-listbox,
#react-select-8-listbox,
#react-select-9-listbox,
#react-select-10-listbox {
  z-index: 9 !important;
}

.successModal {
  text-align: center;

  .modal-header {
    display: none;
  }

  h5 {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: var(--black);
  }

  .sub-title {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: var(--black);
  }
}

.success-btn {
  background: #6aa84f !important;
  border-radius: 5px !important;
  width: 145px;
}

.cursor-revert {
  cursor: revert !important;
}

.doc-check {
  .form-check-inline {
    @media (max-width: 768px) {
      display: flex;
    }
  }

  .form-check-input {
    @media (max-width: 1300px) {
      margin-right: 3px !important;
    }
  }

  .form-check-label {
    @media (max-width: 1300px) {
      margin-left: 10px !important;
    }
  }

  .online-doc-upload {
    @media (max-width: 1300px) {
      margin-top: 10px !important;
    }
  }
}

.eligible-check {
  .form-check-input {
    @media (max-width: 768px) {
      margin-right: 3px !important;
    }
  }
}

.attachment-ul {
  list-style-type: none;
  padding-left: 1rem;
}

.preview-tool {
  position: relative;
  overflow: hidden;

  iframe {
    width: 100% !important;
    position: relative;
    top: -50px;

    @media (max-width: 768px) {
      position: relative;
      top: -23px;
    }
  }
}

// @-moz-document url-prefix() {
//     .pdf-preview iframe {
//         display: none;
//     }
// }

.boarding-layout {
  .was-validated .form-check-input:valid:checked,
  .form-check-input.is-valid:checked {
    border: 2px solid #0d6efd;
    background-image: url("../../../assets/images/check_tick.svg");
    background-color: #fff !important;
  }

  .was-validated .form-check-input:valid,
  .form-check-input.is-valid {
    border-color: #198754;
  }

  .was-validated .form-check-input:valid ~ .form-check-label {
    color: #000 !important;
  }
}

.type-contractor {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #646569;
}

.custom-select > div {
  padding-bottom: 5px;
  padding-top: 5px;
}

.file-span {
  font-size: 14px;
  color: #555555;
  font-weight: 400;
  max-width: 70%;
}

.personal-date-picker {
  .datepicker-payscheme {
    height: 49px !important;
  }
}
.contact-check {
  .form-check-label {
    margin-top: 6px !important;
  }
}
.was-validated .contact-check .form-check-input:valid:checked,
.form-check-input.is-valid:checked {
  border: 2px solid #0d6efd;
  background-image: url("../../../assets/images/check_tick.svg");
  background-color: #0d6efd !important;
}

.was-validated .contact-check .form-check-input:valid,
.form-check-input.is-valid {
  border-color: #198754;
}

.was-validated .contact-check .form-check-input:valid ~ .form-check-label {
  color: #000 !important;
}
.iframe-wrapper {
  .row {
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: center;
  }
  .button-wrapper {
    width: auto;
  }
}
@media (max-width: 768px) {
  .iframe-wrapper {
    .row {
      flex-direction: column;
    }
    .button-wrapper {
      margin-top: 20px;
      display: flex;
      width: 100%;
      justify-content: space-between;
    }
  }
}

.offer-view-link {
  width: 200px;
  padding: 9px 16px;

  @media (max-width: 299px) {
    width: 82px !important;
  }

  @media (max-width: 768px) {
    display: inline-block;
    text-align: center;
  }

  a {
    color: var(--white);
  }
}

.document-verification-title-wrapper {
  h3 {
    font-family: "Lato";
    font-size: 28px;
    font-weight: 700;
    line-height: 34px;
    color: #252525;
    margin: 0;
  }
}
.document-verification-subtitle-wrapper {
  margin: 2rem 0 0;
  h5 {
    font-family: "Lato";
    font-size: 20px;
    font-weight: 700;
    line-height: 34px;
    color: #252525;
    margin: 0 0 0.5rem;
  }
  p {
    font-family: "Lato";
    font-size: 14px;
    font-weight: 500;
    line-height: 19.6px;
    color: #000a13;
    margin: 0 0 0.5rem;
  }
}
.document-verification-table-wrapper {
  .header_bg_table {
    table {
      width: 100%;
      thead {
        tr {
          th {
            &.table-header {
              background-color: #ebf2f8;
              padding: 1.1rem 1rem;
            }
          }
        }
      }
      tbody {
        tr {
          td {
            color: #515253;
            font-family: "Lato";
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            padding: 1.1rem 1rem;
            span {
              height: 38px;
              display: flex;
              align-items: center;
              justify-content: center;
              &.status {
                padding: 5px 10px;
                border-radius: 10px;
                color: #fff;
                font-family: "Lato";
                font-size: 18px;
                font-weight: 400;
                line-height: 17.6px;

                &.completed {
                  background-color: #34c759;
                }
                &.Incomplete {
                  background-color: #f04b4b;
                }
              }
            }
            a {
              color: #2773b1;
              font-weight: 700;
              text-decoration-line: underline;
              text-decoration-style: solid;
            }
          }
        }
      }
    }
  }
}
.table-button-wrapper {
  button {
    background: #2773b1;
    border: none;
    border-radius: 3px;
    font-family: "Lato";
    font-size: 11px;
    font-weight: 400;
    line-height: 14px;
    color: #fff;
    width: 76px;
    height: 36px;
    text-transform: uppercase;
  }
}
.onboarding-content-wrapper {
  padding: 1rem 0 0;
}
@media (max-width: 600px) {
  .onboarding-content-wrapper {
    padding: 1rem;
  }
}
.sign-action-btn-wrapper {
  button {
    font-family: "Lato";
    font-size: 11px !important;
    font-weight: 400;
    line-height: 14px !important;
    text-transform: uppercase;
    background: #2773b1 !important;
    border-radius: 3px;
    color: #fff;
    width: 78px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
  }
}
