.report-details-wrapper {
  margin: 0.5rem 1rem;
}
.reports-multi-select-wrapper {
  background: #fff;
  padding: 1.5rem;
  h1 {
    font-family: "Lato";
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: -0.025em;
    margin: 0 0 0.5rem;
  }
}
.select-all-wrapper {
  display: flex;
  align-items: center;
  span {
    padding: 0 0 0 0.5rem;
    display: flex;
    cursor: pointer;
  }
  label {
    display: flex;
  }
  input {
    margin: 1px 0 0;
  }
}
.multi-select-container {
  margin: 1rem 0;
  border: 1px solid #e2e8f0;
  box-shadow: 0px 4px 6px 0px #00000017;
  padding: 1.5rem;
}
.multi-section-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin: 0 0 1rem;
}
.select-options-main-wrapper {
  grid-gap: 1rem;
}
.grid-column-condition-wrapper {
  display: flex;
}
.grid-column-non-condition-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.select-options-wrapper {
  display: flex;
  align-items: center;
  input {
    margin: 1px 0 0;
  }
  label {
    padding: 0 0 0 0.5rem;
  }
  span {
    padding: 0 0 0 0.5rem;
    cursor: pointer;
  }
}
.reports-footer-wrapper {
  border-top: 1px solid #e1e1e1;
  display: flex;
  justify-content: end;
  background: #fff;
  padding: 1.5rem;
  button {
    text-transform: uppercase;
    font-family: "Lato";
    font-size: 11px;
    font-weight: 400;
    line-height: 14px;
    text-align: center;
    border: none;
    background: #2773b1;
    color: #fff;
    box-shadow: 0px 1px 3px 0px #0000001a;
    box-shadow: 0px 1px 2px 0px #00000033;
    border-radius: 3px;
    width: 195px;
    height: 36px;
  }
}
.responsive-scroll-text {
  display: none;
}
.form-label-wrapper {
  font-family: "Lato";
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  margin: 0;
  color: #000;
  margin: 0 0 0.5rem;
  span {
    color: red;
  }
}
.report-name-wrapper {
  textarea {
    resize: none;
    border-radius: 1px;
  }
}
.description-wrapper {
  margin: 0.5rem 0;
  position: relative;
  textarea {
    resize: none;
    border-radius: 1px;
  }
}
.char-count {
  position: absolute;
  bottom: 0;
  right: 8px;
  padding: 2px;
  font-size: 12px;
  color: #c4c4c4;
}
.report-modal-header-wrapper {
  padding: 1rem;
}
.column-footer-wrapper {
  padding: 1rem;
}
.note-wrapper {
  margin: 0.75rem 0 !important;
  font-family: "Lato";
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #6e6e6e !important;
}
// Responsive
@media screen and (max-width: 1024px) {
  .multi-select-container {
    overflow-x: scroll;
  }
  .multi-section-wrapper {
    flex-direction: column;
    grid-gap: 1rem;
  }
  .grid-column-non-condition-wrapper {
    width: 900px;
  }
  .grid-column-condition-wrapper {
    // width: 900px;
    flex-wrap: wrap;
  }
  .report-details-wrapper {
    margin: 0.5rem 0;
    .setting-backbutton {
      margin: 0 0.5rem;
    }
  }
  .reports-footer-wrapper {
    margin: 0 0.5rem;
  }
  .select-all-wrapper {
    label {
      display: flex;
    }
  }
  .responsive-scroll-text {
    display: block;
    margin: 0;
  }
  .select-options-wrapper {
    label {
      padding: 0;
    }
  }
}
.btn-cancel-wrapper {
  font-family: "Lato";
  font-size: 11px;
  font-weight: 400;
  line-height: 14px;
  text-align: center;
  padding: 0.75rem 3rem;
  border-radius: 3px;
  color: #2773b1;
  margin: 0 0rem 0 0;
  background: #fff;
  border-radius: 3px;
  border: 1px solid #2773b1;
}

@media screen and (max-width: 768px) {
  .reports-multi-select-wrapper {
    margin: 0.5rem;
  }
  .reports-multi-select-wrapper {
    padding: 0.75rem;
  }
}
@media screen and (max-width: 600px) {
  .reports-footer-wrapper {
    justify-content: center;
  }
  .btn-cancel-wrapper {
    width: 100%;
  }
  .column-footer-wrapper {
    flex-direction: column-reverse;
  }
}
