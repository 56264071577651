.statetax_container {
  .document_icon {
    path {
      stroke: #6e6e6e;
    }
  }

  .statetax_form {
    .statetax_location_name {
      font-family: "Lato";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      color: #000000;
    }

    p {
      font-family: "Lato";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: #000000;
    }

    .grey_text {
      font-family: "Lato";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #6e6e6e;
      margin-bottom: 0px;

      span {
        text-decoration: underline;
        font-weight: bold;
      }

      a {
        text-decoration: underline;
      }
    }

    select {
      // background: #ffffff;
      border: 1px solid #e8e8e8;
      border-radius: 5px;

      .placeholder_text {
        font-family: "Lato";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #aaaaaa;
      }
    }

    .custom_form_select * {
      font-family: "Lato";
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 17px;
      border-bottom: 0.5px solid #e8e8e8;
      color: #000000;
      padding-top: 15px;
      padding-bottom: 15px;
      min-height: 15px;
      font-size: 18px;
    }

    input[type="radio"] {
      border: 1px solid black;
    }

    .form-check {
      label {
        font-family: "Lato";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #000000;
      }
    }

    .statetax_save_btn_container {
      display: flex;
      gap: 10px;
      justify-content: end;

      @media (max-width: 515px) {
        flex-direction: column;
        // gap: 10px;
      }
    }
  }

  .form-check-input:checked[type="radio"] {
    background-image: url("../../../assets/images/radio_checked.svg") !important;
    background-color: white;
    border: 2px solid #0d6efd;
  }

  .was-validated .form-check-input:valid:checked,
  .form-check-input.is-valid:checked {
    background-image: url("../../../assets/images/radio_checked_green.svg") !important;
  }
  .was-validated .form-check-input:valid ~ .form-check-label,
  .form-check-input.is-valid ~ .form-check-label {
    color: #000000;
  }
}
.cus-btn-back-wrapper {
  background: #fff;
}
