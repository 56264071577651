// .multi-card-main-container {
//   display: flex;
// }
.pending-wrapper {
  display: flex;
  align-items: center;
  p {
    margin: 0 4px;
  }
}
.total-pending-hours {
  display: flex;
  flex-direction: column;
  padding: 0 0 0 1rem;
  p {
    font-family: "Lato";
    font-size: 12px;
    font-weight: 700;
    line-height: 20px;
    color: #f04b4b;
    margin: 0;
  }
  h5 {
    font-family: "Lato";
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    color: #f04b4b;
    margin: 0;
  }
}
.multi-card-status-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: start;
  padding: 0rem;
  //   width: 100%;
  background: #fff;
  margin: 0 1rem;
  .pto-log-current-btn {
    height: 50px;
  }
  .pto-log-upcoming-btn {
    height: 50px;
  }
  .pto-log-denied-btn {
    height: 50px;
  }
}
.multi-card-status-current-btn {
  background-color: #fff;
  border: 1px solid #d9d9d9;
  font-family: "Lato";
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  color: #000;
  width: 125px;
  height: 43px;
  border-left: 0.5x solid #d9d9d9;
  border-top: 0.5px solid #d9d9d9;
  border-bottom: 0.5px solid #d9d9d9;
  border-right: 0.5px solid #d9d9d9;
  &:active,
  &.active {
    background-color: #2773b1;
    color: #fff;
  }
}
.multi-card-approve-all-btn-wrapper {
  margin: 0;
  // padding: 0rem 0 0 1rem;
  // border-left: 1px solid #ebebeb;
  justify-content: end;
}

.total-overtime-hours {
  display: flex;
  flex-direction: column;
  p {
    font-family: "Lato";
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    color: #09090b;
    margin: 0;
  }
  h5 {
    font-family: "Lato";
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: #09090b;
    margin: 0;
  }
}
.border-class-cus {
  border-right: 1px solid #e4e4e7;
}
.multi-card-search-approve-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.75rem 1rem;
  background: #fff;
  margin: 0 1rem;
}
.overtime-total-hours-wrapper {
  display: flex;
  // width: 34%;
  justify-content: space-evenly;
}
.padding-wrapper {
  padding: 0 1rem 0 0;
  border-right: 1px solid #e4e4e7;
}
.multi-card-input-search-wrapper {
  width: 360px;
  // border-right: 1px solid #ebebeb;
  // padding: 0 1rem 0 0;
  input {
    border: none;
  }
  .input-group {
    border: 1px solid #e4e4e7;
    border-radius: 4px;
    padding: 8px;
    align-items: center;
  }
}
.multi-filter-container {
  display: flex;
  justify-content: space-between;
  background: #fff;
  margin: 0 1rem;
  padding: 2rem 1rem 0;
  align-items: end;
}
.overtime-sub-wrapper {
  padding: 0 1rem;
  border-right: 1px solid #e4e4e7;
}
.multi-card-select-wrapper {
  display: flex;
  justify-content: space-between;
}
.multi-card-go-to-reports {
  p {
    font-family: "Inter";
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #09090b;
    margin: 0 0 0.25rem;
  }
  .css-b62m3t-container {
    width: 150px;
  }
}
.multi-card-select-sub-wrapper {
  display: flex;
}
.select-frequency-wrapper {
  display: flex;
  flex-direction: column;
  padding: 0 0.5rem 0 0;
  .css-b62m3t-container {
    width: 210px;
  }
  .css-1s2u09g-control {
    height: 40px;
    border: 1px solid #e4e4e7;
  }
  // .css-9gakcf-option {
  //   background-color: #2773b1;
  //   color: #fff;
  //   &:hover {
  //     background-color: #2773b1;
  //     color: #fff;
  //   }
  // }
  p {
    margin: 0 0 0.25rem;
    font-family: "Lato";
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: #09090b;
  }
}
.select-date-range-wrapper {
  display: flex;
  flex-direction: column;
  padding: 0 0.5rem 0 0;
  p {
    margin: 0 0 0.25rem;
    font-family: "Lato";
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: #09090b;
  }
}
.info-btn-wrapper {
  position: relative;
  .css-1s2u09g-control {
    height: 40px;
  }
}
.multi-approval-go-to-button {
  border: 1px solid #2773b1;
  color: #2773b1;
  box-shadow: 0px 1px 3px 0px #0000001a !important;
  margin: 0px;
  text-transform: uppercase;
  font-family: "Lato";
  font-size: 11px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  padding: 10px 10px;
  height: 40px;
  border-radius: 3px;
  &:hover {
    border: 1px solid #2773b1;
    color: #2773b1;
  }
  img {
    padding: 0px 0 0 4px;
  }
}
.filters-btn-wrapper {
  border: 1px solid #e4e4e7;
  box-shadow: 0px 1px 3px 0px #0000001a !important;
  margin: 0px;
  text-transform: uppercase;
  font-family: "Lato";
  font-size: 11px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  padding: 10px 10px;
  height: 40px;
  border-radius: 3px;
  &:hover {
    border: 1px solid #2773b1;
    color: #2773b1;
  }
  img {
    padding: 0px 8px 0 0;
  }
  p {
    cursor: pointer;
  }
}
.multi-approval-export-button {
  background: #2773b1;
  color: #fff !important;
  box-shadow: 0px 1px 2px 0px #00000033;
  text-transform: uppercase;
  font-family: "Lato";
  font-size: 11px;
  font-weight: 400;
  line-height: 14px;
  padding: 10px 0px;
  height: 40px;
  width: 170px;
  border-radius: 3px;
  img {
    padding: 0 0px 0 8px;
  }
  &:hover {
    color: #fff;
  }
}
.date-range-subwrapper {
  border: 1px solid #e4e4e7;
  border-radius: 6px;
  padding: 8px 10px;
  display: flex;
  justify-content: space-between;
  height: 40px;
  align-items: center;
  width: 265px;
  p {
    font-family: "Lato";
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #2773b1;
    margin: 0;
  }
}
.arrow-image-cus {
  cursor: pointer;
}
.date-right-arrow-cus {
  opacity: 0.5;
  cursor: not-allowed;
}
.go-to-reports-sub-section {
  position: absolute;
  display: flex;
  left: 0;
  flex-direction: column;
  top: 95%;
  z-index: 1000;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  background-color: #fff;
  border: 1px solid #ccc;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-clip: padding-box;
  width: 50%;
  a {
    font-weight: 400;
    line-height: 1.52857143;
    color: #5f5f5f;
    font-family: "lato";
  }
}
.width-cus-wrapper {
  width: 308px;
}
.filter-section-wrapper {
  p {
    margin: 0.5rem 0 0;
    cursor: pointer;
  }
}
.filter-sub-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.25rem;
}
.multi-card-header-wrapper {
  display: flex;
}
@media (max-width: 1024px) {
  .multi-card-status-wrapper {
    flex-direction: column-reverse;
    grid-gap: 1rem;
    padding: 0;
    margin: 0.5rem 0;
  }
  .multi-filter-container {
    flex-direction: column;
  }
  .multi-card-go-to-reports {
    width: 100%;
    margin: 0.5rem 0;
    .css-b62m3t-container {
      width: 100%;
    }
    .info-btn-wrapper {
      margin: 0;
      flex-direction: column;
    }
  }
  .multi-card-select-sub-wrapper {
    flex-direction: column;
  }
  .select-frequency-wrapper {
    padding: 0 0 1rem;
    .css-b62m3t-container {
      width: 100%;
    }
  }
  .multi-card-select-wrapper {
    width: 100%;
  }
  .multi-card-select-sub-wrapper {
    width: 100%;
  }
  .date-range-subwrapper {
    width: 100%;
  }
  .multi-card-search-approve-wrapper {
    flex-direction: column;
  }
  .multi-card-input-search-wrapper {
    width: 100%;
    border: none;
    padding: 0 0 1rem;
  }
  .multi-card-approve-all-btn-wrapper {
    padding: 1rem 0;
    border: none;
    flex-direction: column;
    grid-gap: 1rem;
    width: 100%;
  }
  .approve-timeoff-btn {
    margin: 0;
  }
  .total-pending-hours {
    // width: 100%;
    padding: 0 1rem;
    align-items: start;
  }
  .go-to-reports-sub-section {
    left: auto;
    right: 14%;
  }
  .width-cus-wrapper {
    width: 100%;
  }
  .multi-card-header-wrapper {
    flex-direction: column;
    align-items: start;
  }
  .multi-approval-export-button {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .multi-card-status-wrapper {
    padding: 0;
    margin: 0.5rem 0;
    .btn-pto-wrapper {
      justify-content: start;
    }
    .info-btn-wrapper {
      margin: 0;
    }
  }
  .go-to-reports-sub-section {
    width: 90%;
    top: 40%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
  }
}
@media (max-width: 600px) {
  .multi-card-status-current-btn {
    width: -webkit-fill-available;
  }
  .multi-card-approve-all-btn-wrapper {
    margin: 0;
    // justify-content: end;
  }
  .multi-approval-go-to-button {
    margin: 0;
  }
  .filters-btn-wrapper {
    margin: 0;
  }
  .multi-filter-container {
    flex-direction: column;
    margin: 0;
  }
  .multi-card-search-approve-wrapper {
    margin: 0;
    padding: 1rem;
  }
  .multi-card-status-wrapper {
    margin: 0;
  }
  .go-to-reports-sub-section {
    width: 100%;
    top: 40%;
    z-index: 1;
  }
}
