.minimize-sidebar{
.main-layout {
  display: flex;

  .main-body {
    // width: calc(100% - 240px);
    width: calc(100% - 88px);
    // margin-left: 240px;
    margin-left: 88px;

    overflow-y: auto;
    // min-height: calc(100vh - 70px);
    min-height: 100vh;
    transition: 0.01s ease all;

    &.full {
      width: 100%;
      margin-left: 0;
    }
  }
}}

.main-layout {
  display: flex;

  .main-body {
    width: calc(100% - 240px);
    margin-left: 240px;
    overflow-y: auto;
    // min-height: calc(100vh - 70px);
    min-height: 100vh;
    transition: 0.01s ease all;

    &.full {
      width: 100%;
      margin-left: 0;
    }
  }
}

@media (max-width: 700px) {
  .main-body {
    width: 100% !important;
    margin-left: 0px !important;
    padding: 10px !important;
    margin-top: 56px;
  }
}
@media (max-width:700px) {
  .minimize-sidebar{
    .main-body{
      width: calc(100% - 0px) !important;
      margin-left: 88px !important;
    }
  }

  .minimize-sidebar.mobile-minimize-sidebar{
    .main-body{
      width: calc(100% - 0px) !important;
      margin-left: 0px !important;
    }
  }
  
}

@media (min-width:700px) {
  .minimize-sidebar.mobile-minimize-sidebar{
    .main-body{
      width: calc(100% - 88px) !important;
      margin-left: 88px !important;
    }
  }
}