.board-bg {
  background: rgba(0, 57, 109, 0.09);
  border-radius: 2px;
}

.board-container {
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;

  @media (max-width: 600px) {
    padding: 0;
  }
}

.boarding-layout {
  margin-top: 82px;
  padding: 1.5rem;

  @media (max-width: 768px) {
    padding: 0;
  }
}

.create-board {
  background: var(--white);
  padding: 1.5rem;
}

.board-main-section {
  .create-board {
    background: var(--white);

    @media (max-width: 600px) {
      padding: 0;
      min-height: 38rem;
    }
  }
}

.client-d-tab {
  .nav-link.active,
  .show > .nav-link {
    background: var(--primary);
    border-radius: 3px;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  }

  .nav-item {
    margin-top: 10px;
  }

  .nav-link {
    background: var(--white);
    border-radius: 3px;
    padding: 17px;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: var(--grey1);

    .activeIcon {
      display: none;
    }

    &.active {
      svg {
        path {
          fill: var(--white);
        }
      }

      .activeIcon {
        display: inline-block;
      }

      .inactiveIcon {
        display: none;
      }
    }
  }
}

.board-right {
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: var(--black2);
  }

  h5 {
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    color: var(--black1);
  }

  .sub-title {
    font-weight: 400;
    font-size: 16px;
    line-height: 157%;
    color: #787878;
  }
}

.create-btn {
  @media (max-width: 600px) {
    width: 100%;
  }
}

.board-tab-sections {
  h5 {
    @media (max-width: 600px) {
      display: none;
    }
  }
}

.phone-group {
  .input-group-text {
    background-color: var(--white) !important;
    border: 1px solid var(--gray2);
  }

  input {
    border-radius: 0 0.375rem 0.375rem 0 !important;
  }
}

.calendar-icon {
  background-image: url("../../assets/images/calendar_month.png");
  background-position: 50%;
  background-size: cover;
  cursor: pointer;
  height: 17px;
  position: absolute;
  right: 20px;
  top: 43px;
  width: 17px;
}

.form-select {
  -webkit-appearance: menulist !important;
  -moz-appearance: menulist !important;
  -ms-appearance: menulist !important;
  -o-appearance: menulist !important;
  appearance: menulist !important;
}

.pass-invalid {
  li {
    padding-left: 10px;
  }
}
@media (max-width: 1024px) {
  .board-container {
    padding: 0;
  }
}
.onboarding-intro-wrapper {
  background: #fff;
}
.onboarding-intro-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
}
.onboarding-intro-header-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 0 1.5rem;
  h5 {
    font-family: "Lato";
    font-size: 32px;
    font-weight: 700;
    line-height: 38.4px;
    text-align: center;
    margin: 0;
    color: #252525;
    margin: 0.75rem 0;
  }
  p {
    font-family: "Lato";
    font-size: 20px;
    font-weight: 500;
    line-height: 34px;
    text-align: center;
    margin: 0;
    color: #252525;
    margin: 0.75rem 0;
    width: 72%;
  }
}
.onboarding-intro-body {
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  padding: 0 5rem;
}
.onboarding-body-title-content {
  h5 {
    font-family: "Lato";
    font-size: 20px;
    font-weight: 700;
    line-height: 28.8px;
    color: #252525;
    margin: 1rem 0 0.25rem;
  }
  p {
    font-family: "Lato";
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    color: #515253;
    margin: 0.25rem 0 0.75rem;
  }
}
.onboarding-body-points-wrapper {
  p {
    font-family: "Lato";
    font-size: 20px;
    font-weight: 400;
    line-height: 30.8px;
    margin: 0;
    position: relative;
    span {
      font-weight: 600;
    }
  }
}
.onboarding-body-points-sub-wrapper {
  display: flex;
  align-items: center;
  margin: 1.75rem 0;
  grid-gap: 1.25rem;
}
.span-cus-wrapper {
  box-shadow: 0px 0px 2.6px 0px #0000001a;
  background: #ebf2f8;
  font-family: "Lato";
  font-size: 24px;
  font-weight: 700;
  line-height: 20px;
  color: #2773b1;
  padding: 16px 18px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.onboarding-intro-footer {
  padding: 1rem 5rem;
  display: flex;
  justify-content: end;
  button {
    background: #2773b1;
    width: 150px;
    height: 40px;
    border-radius: 3px;
    text-transform: uppercase;
    box-shadow: 0px 1px 3px 0px #0000001a;
    box-shadow: 0px 1px 2px 0px #00000033;
    color: #fff;
    font-family: "Lato";
    font-size: 11px;
    font-weight: 400;
    line-height: 14px;
    border: none;
  }
}
@media (max-width: 600px) {
  .onboarding-intro-header {
    padding: 2rem 0 1rem;
  }
  .onboarding-intro-header-content {
    h5 {
      font-size: 22px;
      line-height: 30.4px;
    }
    p {
      font-size: 18px;
      line-height: 30px;
      width: 100%;
      margin: 0 1rem;
    }
  }
  .onboarding-intro-body {
    padding: 1rem;
  }
  .onboarding-body-title-content {
    h5 {
      font-size: 18px;
      line-height: 26px;
    }
    p {
      font-size: 16px;
      line-height: 24px;
    }
  }
  .onboarding-body-points-wrapper {
    p {
      font-size: 16px;
      line-height: 24px;
    }
  }
  .span-cus-wrapper {
    font-size: 16px;
    padding: 4px 10px;
  }
  .onboarding-intro-footer {
    justify-content: center;
  }
}
