.custom-time-picker {
  position: relative;
  display: inline-block;

  .time-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;

    &:focus {
      outline: none;
      border-color: #007bff;
    }
  }
}
.time-dropdown {
  border: 1px solid #e4e4e7;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  .time-option {
    display: flex;
    align-items: center;
    .time-select {
      // margin: 0 5px;
      padding: 5px 2px;
      border: none;
      background-color: #fff;
      color: #71717a;
      font-family: "Lato";
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      outline: 0;
      &:disabled {
        background-color: #f0f0f0;
        color: #888;
      }
    }
  }

  .time-select-button {
    margin-left: 10px;
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    cursor: pointer;

    &:hover {
      background-color: #0056b3;
    }
  }
}
