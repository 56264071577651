.login-body {
  background: rgba(0, 57, 109, 0.09);
  backdrop-filter: blur(285px);
}

.login-header {
  padding: 1.5rem;
}

.login-section {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  min-height: calc(100vh - 158px);
  width: 100%;
  margin-left: 0;

  .content {
    padding: 30px;

    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: var(--black2);
      ;
    }

    h5 {
      font-weight: 700;
      font-size: 30px;
      line-height: 36px;
      color: var(--black1);
    }

    .sub-title {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: var(--black2);
    }

    .back-btn {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: var(--black2);
      vertical-align: middle;
    }
  }



  .forgot-text {
    color: var(--blue);
    text-align: end;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  }

  button {
    width: 100%;
    background: var(--blue);
    border-radius: 5px;
    line-height: 19px;
    height: 50px;
    font-size: 16px !important;
  }

}

.custom-form {
  .form-label {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: var(--black);
  }

  .form-control,
  .form-select {
    color: var(--black1) !important;
    height: 50px;
    padding: 10px 25px;
    background: var(--white);
    border: 1px solid var(--gray2);
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;

  }

  .form-check .form-check-input {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    border: 1px solid var(--black);

  }
  #react-select-4-listbox{
    z-index: 99 !important;
  }
}


.check-custom {
  .form-check .form-check-input {
    @media (max-width: 600px) {
      width: 43px;
      height: 27px;
    }
  }
}


.datepicker-payscheme {
  color: var(--black1) !important;
  height: 50px;
  padding: 10px 25px;
  background: var(--white);
  border: 1px solid var(--gray2);
  border-radius: 5px;
  font-weight: 400;
  font-size: 14px;
  width: 100%;
}

.cursor {
  cursor: pointer;
}

.error-msg {
  font-size: 11px;
  font-weight: normal;
  border: none !important;
  text-align: start;
  display: block;
  color: var(--red);
}

.form-control.is-valid,
.was-validated .form-control:valid {
  background-image: none !important;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-image: none !important;
  border: 1px solid var(--red) !important;
}


.invalid-feedback {
  color: var(--red);
}

.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:valid:not([multiple]):not([size]),

.was-validated .form-select:invalid:not([multiple])[size="1"],
.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size="1"] {
  background-image: none;
}
.feedback-remove {
  #sleek-button ,.zsiq_floatmain{
    display: none !important;
  }
}