.sidebar {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 240px;
  height: 100vh;
  padding-left: 16px;
  background: #fff;
  z-index: 1;
  overflow-y: auto;
  transition: all 0.2s;
  padding-bottom: 64px;

  /* Works on Firefox */

  scrollbar-width: thin;
  scrollbar-color: #f1f1f1 #c1c1c1;


  /* Works on Chrome, Edge, and Safari */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 20px;
  }

  .logo-icon-new {
    // margin-top: 9px;
    margin-bottom: 14px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .side-expand {
    margin: 5px 0px 29px 4px;

    img {
      width: 42px;
      height: 29px;
      cursor: pointer;
    }

  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      position: relative;
      overflow: hidden;
      cursor: pointer;

      a {
        display: flex;
        align-items: center;
        padding: 16px 0;
        position: relative;

        .nav-new-icon {
          img {
            width: 80%;
          }
        }

        div {
          &:nth-child(1) {
            margin-right: 20px;
            margin-left: 15px;
            width: 24px;
            height: 24px;
          }
        }

        .new-nav-text {
          color: #515253;
          font-weight: 500;
          font-size: 14px;
          text-transform: uppercase;
          margin-bottom: -3px;
          white-space: nowrap;
        }



        &:hover,
        &.active {
          &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            border: 0;
            height: 100%;
            border-left: 5px solid var(--blue);
          }

          .new-nav-text {
            color: var(--blue);
          }

          svg {
            path {
              fill: var(--blue);
            }

          }

          img {
            filter: invert(39%) sepia(100%) saturate(400%) hue-rotate(163deg) brightness(85%) contrast(91%);
          }
        }

      }

    }

    .sub-menu {
      li {
        margin-left: 20px;

        a {
          .sub-icon {
            margin-right: 15px;
            margin-left: 15px;
            width: 15px;
            height: 24px;
            // margin-top: -5px;
          }


          .submenu-text {
            color: #515253;
            font-weight: 500;
            font-size: 12px;
            text-transform: uppercase;
            margin-bottom: -3px;
          }

          &:hover,
          &.active {
            .submenu-text {
              color: var(--blue);
            }
          }

        }
      }
    }
  }


}

.close-sidebar {
  width: 88px;
  overflow-x: hidden;
}






.mobileLogoIcon {
  max-width: 40px;
  max-height: 40px;
}

.d-hide {
  display: none;
}

.mobile-sidebar-view {
  display: none;
}

@media (max-width: 700px) {

  .desk-view {
    display: none !important;
  }

  .mobile-sidebar-view {
    display: block;
    background: var(--white);
    width: 100%;
    height: 56px;
    position: fixed;
    z-index: 99;
  }
}

.mobile-logo {
  padding: 10px 16px 10px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .logo-icon {
    padding: 0 !important;
  }
}

// mobile header

@media (max-width: 700px) {
  .modal-open {
    .sidebar {
      z-index: unset;
    }
  }

  .sidebar {
    margin-top: 56px;
    z-index: 9999;
    padding-left: 0px;
  }

  .mobile-minimize-sidebar {
    .sidebar {
      width: 0px;
      padding-left: 0px;
    }
  }

}

.mobile_menu_gap {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.new-fav {
  margin-left: 10px;
}

.mobile-submenu {
  img {
    width: 20px;
    height: 20px;
  }
}

.new-fav-test {
  display: flex;
  position: relative;
  padding-right: 15px;
}

.max-logo-new {
  max-width: 180px;
  max-height: 65px;
}

.max-fav-logo-new {
  max-width: 60px;
  max-height: 40px;
  margin: 0 auto;
}

.desk-logo {
  text-align: center;
  padding-right: 16px;
}
.mobile-with-bell{
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;

  .bell{
    width: 32px;
    height: 25px;
    margin-right: 20px;
  }
}


.max-logo-new {
  max-width: 180px;
  max-height: 65px;
}

.max-fav-logo-new {
  max-width: 60px;
  max-height: 40px;
  margin: 0 auto;
}

.desk-logo {
  text-align: center;
  padding-right: 16px;
}

.color_set {
  color: #ffff;
  background-color: #00396D;
  border-radius: 5px;
  width: 180px;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.web_size{
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
}
.mob_size{
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
}
.mob__header_size{
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  width: 100px !important;
  height: 30px !important;
}
.notify_bell{
    margin-top: 3px;
  }

.mobile-with-bell img{
  margin-top: 2px;
}